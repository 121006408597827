import { createRouter, createWebHistory } from 'vue-router'
// import apec from '@/views/apec'
import glbal from '@/views/global'
// import { Const } from '@/plugins/commonAll/provider/lib/const.cls.ts'

const routes = [
  // {
  //   path: '/au', 
  //   component: apec, 
  //   children: [
  //     { path: "/", name: "index1", component: () => import("@/views/apec/home/home.vue"), requiresAuth: false },
  //     { path: "supported-coin", name: "supportedcoin1", component: () => import("@/views/apec/supported-coin/supported-coin"), requiresAuth: false },
  //     { path: "payout", name: "payout1", component: () => import("@/views/apec/payout/payout"), requiresAuth: false },
  //     { path: "pricing", name: "pricing1", component: () => import("@/views/apec/pricing/pricing"), requiresAuth: false },
  //     { path: "contact-us", name: "Contact Us1", component: () => import("@/views/apec/contact-us/contact-us"), requiresAuth: false },
  //     { path: "merchant-tools-plugin", name: "Mechant Tools Plugin1", component: () => import("@/views/apec/merchant-tools-plugin/merchant-tools-plugin"), requiresAuth: false,},
  //     { path: "merchant-tools-woocommerce", name: "Mechant Tools Woocommerce1", component: () => import("@/views/apec/merchant-tools-woocommerce/merchant-tools-woocommerce"), requiresAuth: false, },
  //     { path: "crypto-conversion", name: "Crypto Conversion1", component: () => import("@/views/apec/crypto-conversion/crypto-conversion"), requiresAuth: false, },
  //     { path: "help-centre", name: "Help Centre1", component: () => import("@/views/apec/help-centre/help-centre"), requiresAuth: false, },
  //     { path: "help-centre/:category/:article", name: "detail1", component: () => import("@/views/apec/help-centre/details"), requiresAuth: false, },
  //     { path: "help-centre/:category", name: "articles1", component: () => import("@/views/apec/help-centre/articels"), requiresAuth: false,},
  //     { path: "merchant-solution", name: "Merchant Solution1", component: () => import("@/views/apec/merchant-solution/merchant-solution"), requiresAuth: false,},
  //     { path: "security", name: "Security1", component: () => import("@/views/apec/security/security"), requiresAuth: false,},
  //     { path: "press-release", name: "Press Release1", component: () => import("@/views/apec/press-release/press-release"), requiresAuth: false,},
  //     { path: "about-us", name: "About Us1", component: () => import("@/views/apec/about-us/about-us"), requiresAuth: false,},
  //     { path: "the-insider", name: "The Insider1", component: () => import("@/views/apec/the-insider/the-insider"), requiresAuth: false,},
  //     { path: "the-insider-detail", name: "The Insider Detail1", component: () => import("@/views/apec/the-insider-detail/the-insider-detail"), requiresAuth: false,},
  //     { path: "terms-of-use", name: "Terms of Use1", component: () => import("@/views/apec/terms-of-use/terms-of-use"), requiresAuth: false,},
  //     { path: "aml-policy", name: "AML Policy1", component: () => import("@/views/apec/aml-policy/aml-policy"), requiresAuth: false,},
  //     { path: "privacy-policy", name: "Privacy Policy1", component: () => import("@/views/apec/privacy-policy/privacy-policy"), requiresAuth: false,},
  //     { path: "cookies-policy", name: "Cookies Policy1", component: () => import("@/views/apec/cookies-policy/cookies-policy"), requiresAuth: false,},
  //     { path: "risk-warning", name: "Risk Warning1", component: () => import("@/views/apec/risk-warning/risk-warning"), requiresAuth: false,},
  //     { path: "merchant-agreement",name: "Merchant Agreement1",component: () => import("@/views/apec/merchant-agreement/merchant-agreement"),requiresAuth: false,},
  //     { path: "page-not-found",name: "404 Page1",component: () => import("@/views/apec/page-not-found/page-not-found"),requiresAuth: false,}
  //   ]
  // },
  {
    path: '/au/', 
    component: glbal, 
    children: [
      { path: "", name: "index1", component: () => import("@/views/global/home/home.vue"), requiresAuth: false },
      { path: "supported-coin", name: "supportedcoin1", component: () => import("@/views/global/supported-coin/supported-coin"), requiresAuth: false },
      { path: "payout", name: "payout1", component: () => import("@/views/global/payout/payout"), requiresAuth: false },
      { path: "pricing", name: "pricing1", component: () => import("@/views/global/pricing/pricing"), requiresAuth: false },
      { path: "contact-us", name: "Contact Us1", component: () => import("@/views/global/contact-us/contact-us"), requiresAuth: false },
      { path: "merchant-tools-plugin", name: "Mechant Tools Plugin1", component: () => import("@/views/global/merchant-tools-plugin/merchant-tools-plugin"), requiresAuth: false,},
      { path: "merchant-tools-woocommerce", name: "Mechant Tools Woocommerce1", component: () => import("@/views/global/merchant-tools-woocommerce/merchant-tools-woocommerce"), requiresAuth: false, },
      { path: "crypto-conversion", name: "Crypto Conversion1", component: () => import("@/views/global/crypto-conversion/crypto-conversion"), requiresAuth: false, },
      { path: "help-centre", name: "Help Centre1", component: () => import("@/views/global/help-centre/help-centre"), requiresAuth: false, },
      { path: "help-centre/:category/:article", name: "detail1", component: () => import("@/views/global/help-centre/details"), requiresAuth: false, },
      { path: "help-centre/:category", name: "articles1", component: () => import("@/views/global/help-centre/articels"), requiresAuth: false,},
      { path: "merchant-solution", name: "au.Merchant_Solution1", component: () => import("@/views/global/merchant-solution/merchant-solution"), requiresAuth: false,},
      { path: "security", name: "Security1", component: () => import("@/views/global/security/security"), requiresAuth: false,},
      { path: "press-release", name: "Press Release1", component: () => import("@/views/global/press-release/press-release"), requiresAuth: false,},
      { path: "about-us", name: "About Us1", component: () => import("@/views/global/about-us/about-us"), requiresAuth: false,},
      { path: "the-insider", name: "The Insider1", component: () => import("@/views/global/the-insider/the-insider"), requiresAuth: false,},
      { path: "the-insider-detail", name: "The Insider Detail1", component: () => import("@/views/global/the-insider-detail/the-insider-detail"), requiresAuth: false,},
      { path: "terms-of-use", name: "Terms of Use1", component: () => import("@/views/global/terms-of-use/terms-of-use"), requiresAuth: false,},
      { path: "aml-policy", name: "AML Policy1", component: () => import("@/views/global/aml-policy/aml-policy"), requiresAuth: false,},
      { path: "privacy-policy", name: "Privacy Policy1", component: () => import("@/views/global/privacy-policy/privacy-policy"), requiresAuth: false,},
      { path: "cookies-policy", name: "Cookies Policy1", component: () => import("@/views/global/cookies-policy/cookies-policy"), requiresAuth: false,},
      { path: "risk-warning", name: "Risk Warning1", component: () => import("@/views/global/risk-warning/risk-warning"), requiresAuth: false,},
      { path: "merchant-agreement",name: "Merchant Agreement1",component: () => import("@/views/global/merchant-agreement/merchant-agreement"),requiresAuth: false,},
      { path: "corporate-agreement",name: "Corporate Agreement1",component: () => import("@/views/global/corporate-agreement/corporate-agreement"),requiresAuth: false,},
      // { path: "page-not-found",name: "404 Page1",component: () => import("@/views/global/page-not-found/page-not-found"),requiresAuth: false,}
    ]
  },
  {
    path: '/global/', 
    component: glbal, 
    children: [
      { path: "", name: "index", component: () => import("@/views/global/home/home.vue"), requiresAuth: false },
      { path: "supported-coin", name: "supportedcoin", component: () => import("@/views/global/supported-coin/supported-coin"), requiresAuth: false },
      { path: "payout", name: "payout", component: () => import("@/views/global/payout/payout"), requiresAuth: false },
      { path: "pricing", name: "pricing", component: () => import("@/views/global/pricing/pricing"), requiresAuth: false },
      { path: "contact-us", name: "Contact Us", component: () => import("@/views/global/contact-us/contact-us"), requiresAuth: false },
      { path: "merchant-tools-plugin", name: "Mechant Tools Plugin", component: () => import("@/views/global/merchant-tools-plugin/merchant-tools-plugin"), requiresAuth: false,},
      { path: "merchant-tools-woocommerce", name: "Mechant Tools Woocommerce", component: () => import("@/views/global/merchant-tools-woocommerce/merchant-tools-woocommerce"), requiresAuth: false, },
      { path: "crypto-conversion", name: "Crypto Conversion", component: () => import("@/views/global/crypto-conversion/crypto-conversion"), requiresAuth: false, },
      { path: "help-centre", name: "Help Centre", component: () => import("@/views/global/help-centre/help-centre"), requiresAuth: false, },
      { path: "help-centre/:category/:article", name: "detail", component: () => import("@/views/global/help-centre/details"), requiresAuth: false, },
      { path: "help-centre/:category", name: "articles", component: () => import("@/views/global/help-centre/articels"), requiresAuth: false,},
      { path: "merchant-solution", name: "Merchant Solution", component: () => import("@/views/global/merchant-solution/merchant-solution"), requiresAuth: false,},
      { path: "security", name: "Security", component: () => import("@/views/global/security/security"), requiresAuth: false,},
      { path: "press-release", name: "Press Release", component: () => import("@/views/global/press-release/press-release"), requiresAuth: false,},
      { path: "about-us", name: "About Us", component: () => import("@/views/global/about-us/about-us"), requiresAuth: false,},
      { path: "the-insider", name: "The Insider", component: () => import("@/views/global/the-insider/the-insider"), requiresAuth: false,},
      { path: "the-insider-detail", name: "The Insider Detail", component: () => import("@/views/global/the-insider-detail/the-insider-detail"), requiresAuth: false,},
      { path: "terms-of-use", name: "Terms of Use", component: () => import("@/views/global/terms-of-use/terms-of-use"), requiresAuth: false,},
      { path: "aml-policy", name: "AML Policy", component: () => import("@/views/global/aml-policy/aml-policy"), requiresAuth: false,},
      { path: "privacy-policy", name: "Privacy Policy", component: () => import("@/views/global/privacy-policy/privacy-policy"), requiresAuth: false,},
      { path: "cookies-policy", name: "Cookies Policy", component: () => import("@/views/global/cookies-policy/cookies-policy"), requiresAuth: false,},
      { path: "risk-warning", name: "Risk Warning", component: () => import("@/views/global/risk-warning/risk-warning"), requiresAuth: false,},
      { path: "merchant-agreement",name: "Merchant Agreement",component: () => import("@/views/global/merchant-agreement/merchant-agreement"),requiresAuth: false,},
      { path: "corporate-agreement",name: "Corporate Agreement",component: () => import("@/views/global/corporate-agreement/corporate-agreement"),requiresAuth: false,},
      // { path: "page-not-found",name: "404 Page",component: () => import("@/views/global/page-not-found/page-not-found"),requiresAuth: false,}
    ]
  }
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
})

router.beforeEach((to, from, next) => {
    // let  getEntityFromUrl = this.$route.path.split('/')[1]
    // console.log(to.path)
    localStorage.setItem('route_path', to.path)

        // console.log('watch===',to)
    // if (from.query.transfer || to.query.transfer) {
    //     let transfer = (from.query.transfer) ? from.query.transfer : to.query.transfer
    //     localStorage.setItem('transfer_token', transfer)
    // }
    // window.scrollTo(0, 0)
    // if (userStateVariable == '') { userStateVariable = {} }

    // if (to.name !== 'login' && to.name !== 'forgot_password' && to.name !== 'reset_password' && to.name !== 'email_step' && to.name !== 'otp_step' && to.name !== 'password_step' && (userStateVariable == null ||  !userStateVariable.loggedIn)) {
    //     next({ name: 'login' })
    // }

    // else if (to.name != 'enforce2faRegister' && to.name != 'enforce2faRegister' && userStateVariable  && userStateVariable.loggedIn && parseInt(userStateVariable.profile.gAuthenticatorEnabled) == 0) {
    //     next({ name: 'enforce2faRegister' })
    // }

    // else if ((to.name == 'verification_steps' ) && (userStateVariable != null || userStateVariable.loggedIn) && ( parseInt(userStateVariable.step) >= 5)) {
    //     next({ name: 'dashboard' })
    // }

    // else if (userStateVariable !== null &&   userStateVariable['loggedIn'] && userStateVariable.loggedIn && (to.name == 'login' || to.name == 'forgot_password' || to.name == 'reset_password' || to.name == 'email_step' || to.name == 'otp_step' || to.name == 'password_step')) {
    //     next({ name: 'dashboard' })
    // }
    // else 
    next()
})

export default router