<template>
   <footer class="main-footer blackBG pb-0">
      <div class="container">
         <div class="row">
            <div class="col-12 col-lg-3 text-center text-md-start">
               <img class="d-block fluid-img m-auto m-md-0" src="/assets/images/logo.webp" alt="Capital Wallet Logo" title="Capital Wallet Logo" width="150" height="34" />
               <p class="mt-3" v-if="entityName == 'apec'">{{contentlist.footer_content1}}</p>
               <!-- <p class="mt-3" v-else>{{contentlist.footer_content1_glo}}</p> -->
               

               <!-- APEC Start -->
               <div class="acn" v-if="entityName == 'apec'">
                  <p class="mb-0">{{contentlist.footer_apec_content2}}</p>
                  <p class="footnote">{{contentlist.footer_apec_content3}}</p>
               </div>
               <!-- APEC End -->

               <!-- Global Start -->
               <div class="acn" v-else>
                  <!-- <p class="mb-0">{{contentlist.footer_global_content2}}</p> -->
                  <!-- <p class="footnote">{{contentlist.footer_global_content3}}</p> -->
               </div>
               <!-- Global End -->

            </div>
            <div class="col-12 col-lg-9">
               <div class="row">
                  <div class="col col1">
                     <div class="followLinks">
                        <h6 class="semibold pb-3 pb-md-0 mb-3 mb-md-0" v-on:click="footerTabs1 =!footerTabs1" v-bind:class="[{active:footerTabs1}]">{{contentlist.for_business_content}}</h6>
                        <ul class="showtagMobile">
                           <li><router-link :to="entityPath+'/merchant-solution'">{{contentlist.merchant_solution_content}}</router-link></li>
                           <li><router-link :to="entityPath+'/supported-coin'">{{contentlist.supported_coins_content}}</router-link></li>
                           <li><router-link :to="entityPath+'/pricing'">{{contentlist.pricing_content}}</router-link></li>
                        </ul>
                     </div>
                  </div>
                  <div class="col col2">
                     <div class="followLinks">
                        <h6 class="semibold pb-3 pb-md-0 mb-3 mb-md-0" v-on:click="footerTabs2 =!footerTabs2" v-bind:class="[{active:footerTabs2}]">{{contentlist.features_content}}</h6>
                        <ul class="showtagMobile">
                           <li><router-link :to="entityPath+'/crypto-conversion'">{{contentlist.conversions_content}}</router-link></li>
                           <li><router-link :to="entityPath+'/payout'">{{contentlist.payouts_content}}</router-link></li>
                        </ul>
                     </div>
                  </div>
                  <div class="col col3">
                     <div class="followLinks">
                        <h6 class="semibold pb-3 pb-md-0 mb-3 mb-md-0" v-on:click="footerTabs3 =!footerTabs3" v-bind:class="[{active:footerTabs3}]">{{contentlist.resources_content}}</h6>
                        <ul class="showtagMobile">
                           <li><router-link :to="entityPath+'/help-centre'">{{contentlist.help_centre_content}}</router-link></li>
                           <li><a href="https://blog.capitalwallet.com/" target="_blank">{{contentlist.the_insider_content}}</a></li>
                           <li><a href="https://apidocs.capitalwallet.com/" target="_blank">{{contentlist.api_documentation_content}}</a></li>
                        </ul>
                     </div>
                  </div>
                  <div class="col col4">
                     <div class="followLinks">
                        <h6 class="semibold pb-3 pb-md-0 mb-3 mb-md-0" v-on:click="footerTabs4 =!footerTabs4" v-bind:class="[{active:footerTabs4}]">{{contentlist.company_content}}</h6>
                        <ul class="showtagMobile">
                           <li><router-link :to="entityPath+'/about-us'">{{contentlist.about_us_content}}</router-link></li>
                           <li><router-link :to="entityPath+'/security'">{{contentlist.security_content}}</router-link></li>
                           <li><router-link :to="entityPath+'/press-release'">{{contentlist.press_release_content}}</router-link></li>
                           <li><router-link :to="entityPath+'/contact-us'">{{contentlist.contact_us_content}}</router-link></li>
                        </ul>
                     </div>
                  </div>
                  <div class="col col5">
                     <div class="followLinks">
                        <h6 class="semibold border-0 pb-3 pb-md-0 mb-0" v-on:click="footerTabs5 =!footerTabs5" v-bind:class="[{active:footerTabs5}]">{{contentlist.legal_content}}</h6>
                        <ul class="showtagMobile">
                           <li><router-link :to="entityPath+'/terms-of-use'">{{contentlist.terms_of_use_content}}</router-link></li>
                           <li><router-link :to="entityPath+'/aml-policy'">{{contentlist.aml_policy_content}}</router-link></li>
                           <li><router-link :to="entityPath+'/privacy-policy'">{{contentlist.privacy_policy_content}}</router-link></li>
                           <li><router-link :to="entityPath+'/cookies-policy'">{{contentlist.cookies_policy_content}}</router-link></li>
                           <li><router-link :to="entityPath+'/risk-warning'">{{contentlist.risk_warning_content}}</router-link></li>
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
            <div class="col-12 disclaimer text-center">
               <p class="f-16 mb-0 border-top border-bottom mb-3 py-3">{{contentlist.footer_content4}}</p>
            </div>
            <div class="col-12 copyRight text-center d-flex align-items-center justify-content-between mb-3 mt-0 mt-lg-2">
               <p class="f-16 mb-0">{{contentlist.footer_content5}}</p>
               <ul class="footer-social-icon d-flex align-items-center mb-0">
                  <li>
                     <a target="_blank" href="https://www.facebook.com/capitalwalletau/"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                  </li>
                  <li>
                     <a target="_blank" href="https://twitter.com/Capitalwallet_">
                        <img class="d-block" src="/assets/images/twitter.webp" alt="Twitter" title="Twitter" width="18" height="18" />
                     </a>
                  </li>
                  <!-- <li>
                     <a target="_blank" href="https://twitter.com/Capitalwallet_"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                  </li> -->
                  <li>
                     <a target="_blank" href="https://www.instagram.com/capitalwallet_/"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                  </li>
                  <li>
                     <a target="_blank" href="https://www.reddit.com/r/CapitalWallet/"><i class="fa fa-reddit-alien" aria-hidden="true"></i></a>
                  </li>
                  <li>
                     <a target="_blank" href="https://www.linkedin.com/company/capitalwalletglobal/"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
                  </li>
               </ul>
            </div>
         </div>
      </div>
   </footer>
</template>
<script>
import { commonAllmixins } from '@/plugins/commonAll'   //anamica
   export default {
   mixins: [ commonAllmixins],    //anamica
   data() {
   return {
            footerTabs1:false,
            footerTabs2:false,
            footerTabs3:false,
            footerTabs4:false,
            footerTabs5:false,
         };
      },
      created(){
console.log(this.contentlist)
      }
   };
</script>