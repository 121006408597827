export default {
    merchantPortalLinks : {
        'apec':{
            'login' : 'https://client.capitalwallet.com',
            'register' : 'https://client.capitalwallet.com/register'
        },
        'global':{
            'login' : 'https://cp.capitalwallet.com',
            'register' : 'https://cp.capitalwallet.com/register'
        },
        '':{
            'login' : '',
            'register' : ''
        },
    },
    limitPerPage: 10,
    registrationType: [
        { label: "Individual", value: "0" },
        { label: "Corporate", value: "1" }
    ],
    leverages: [
        { label: "100X", value: "100" },
        { label: "200X", value: "200" },
        { label: "300X", value: "300" },
        { label: "400X", value: "400" },
        { label: "500X", value: "500" }
    ],
    accountTypes: [
        { label: 'ECN', value: 'ECN' },
        { label: 'ECN PRO', value: 'ECNP' },
        { label: 'Classic', value: 'CLASSIC' },
        { label: 'Profit Sharing', value: 'PROFIT_SHARING' }
    ],
    platforms: [
        { label: 'MetaTrader 4', value: 'MT4' },
        { label: 'MetaTrader 5', value: 'MT5' },
        { label: 'Zulu Trading Platform', value: 'ZTP' }
    ],
    currency: {
        'USD': '$',
        'GBP': '£',
        'EUR': '€',
    },
    //METHODS FROM FXVIEW
    // depositMethod: { '1': '"Bank"', '2': '"Skrill"', '3': '"Neteller"', '4': '"Trading Account"', '5': '"Debit/Credit card"', '6': '"Giropay"', '7': '"Przelewy"', '8': '"Rapid Transfer"', '9': '"Nordea"', '10': '"Klarna"', '11': '"NBP"', '12': '"TR"', '13': '"CR"', '15': '"Loyalty Transfer"', '18': '"Bitcoin"', '17': '"Praxis"', '19': '"LBT-Gatech"', '20': '"LBT-Payguru"', '21': '"LBT-PayRetailer"', '22': '"LBT-Rapid OZOW"' }
    depositMethods: {
        keys: ['All', 'Bank Cards', 'Bank Wire', 'E-Wallet', 'Cryptos'],
        methods: [
            {
                type: 'Bank Cards',
                minimumDeposit: 100,
                methodID: 5,
                name: 'Credit Cards',
                image: 'dep1.png',
                region: 'World Wide',
                currencies: 'EUR, USD, CHF, GBP',
                processingTime: 'Instant',
                action: ''
            },
            {
                type: 'Bank Wire',
                minimumDeposit: 100,
                methodID: 2,
                name: 'Eurobank',
                image: 'dep2.png',
                region: 'World Wide',
                currencies: 'USD',
                processingTime: '2-3 business days',
                action: ''
            },
            {
                type: 'Bank Wire',
                minimumDeposit: 100,
                methodID: 3,
                name: 'Dixipay',
                image: 'dep3.png',
                region: 'Europe',
                currencies: 'EUR (Sepa Only)',
                processingTime: '2-3 business days',
                action: ''
            },
            {
                type: 'Bank Wire',
                minimumDeposit: 100,
                methodID: 4,
                name: 'Help 2 Pay',
                image: 'dep4.png',
                region: 'South East Asia',
                currencies: 'MY, TH, ID, PH, VN',
                processingTime: 'Instant',
                action: ''
            },
            {
                type: 'Bank Wire',
                minimumDeposit: 100,
                methodID: 5,
                name: 'Pay Retailers',
                image: 'dep5.png',
                region: 'Brazil, Mexico, Chile',
                currencies: 'BRL, MXN, CLP',
                processingTime: 'Instant',
                action: ''
            },
            {
                type: 'E-Wallet',
                minimumDeposit: 100,
                methodID: 6,
                name: 'Virtual Pay',
                image: 'dep6.png',
                region: 'East Africa',
                currencies: 'TAS, KES, MTN',
                processingTime: '2-3 business days',
                action: ''
            },
            {
                type: 'E-Wallet',
                minimumDeposit: 100,
                methodID: 2,
                name: 'Skrill',
                image: 'dep7.png',
                region: 'World Wide',
                currencies: 'EUR, USD, JPY, CHF, GBP, AUD',
                processingTime: '2-3 business days',
                action: ''
            },
            {
                type: 'E-Wallet',
                minimumDeposit: 100,
                methodID: 3,
                name: 'Neteller',
                image: 'dep8.png',
                region: 'World Wide',
                currencies: 'MY, TH, ID, PH, VN',
                processingTime: 'Instant',
                action: ''
            },
            {
                type: 'Cryptos',
                minimumDeposit: 100,
                methodID: 9,
                name: 'Bitcoin',
                image: 'bitcoin-ico.png',
                region: 'World Wide',
                currencies: 'BTC',
                processingTime: '30 mins',
                action: ''
            },
        ]
    },
    timezones: [
        { value: "-12:00", label: '(GMT -12:00) Eniwetok, Kwajalein' },
        { value: "-11:00", label: '(GMT -11:00) Midway Island, Samoa' },
        { value: "-10:00", label: '(GMT -10:00) Hawaii' },
        { value: "-09:50", label: '(GMT -9:30) Taiohae' },
        { value: "-09:00", label: '(GMT -9:00) Alaska' },
        { value: "-08:00", label: '(GMT -8:00) Pacific Time (US &amp; Canada)' },
        { value: "-07:00", label: '(GMT -7:00) Mountain Time (US &amp; Canada)' },
        { value: "-06:00", label: '(GMT -6:00) Central Time (US &amp; Canada), Mexico City' },
        { value: "-05:00", label: '(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima' },
        { value: "-04:50", label: '(GMT -4:30) Caracas' },
        { value: "-04:00", label: '(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz' },
        { value: "-03:50", label: '(GMT -3:30) Newfoundland' },
        { value: "-03:00", label: '(GMT -3:00) Brazil, Buenos Aires, Georgetown' },
        { value: "-02:00", label: '(GMT -2:00) Mid-Atlantic' },
        { value: "-01:00", label: '(GMT -1:00) Azores, Cape Verde Islands' },
        { value: "+00:00", label: '(GMT) Western Europe Time, London, Lisbon, Casablanca' },
        { value: "+01:00", label: '(GMT +1:00) Brussels, Copenhagen, Madrid, Paris' },
        { value: "+02:00", label: '(GMT +2:00) Kaliningrad, South Africa' },
        { value: "+03:00", label: '(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg' },
        { value: "+03:50", label: '(GMT +3:30) Tehran' },
        { value: "+04:00", label: '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi' },
        { value: "+04:50", label: '(GMT +4:30) Kabul' },
        { value: "+05:00", label: '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent' },
        { value: "+05:50", label: '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi' },
        { value: "+05:75", label: '(GMT +5:45) Kathmandu, Pokhara' },
        { value: "+06:00", label: '(GMT +6:00) Almaty, Dhaka, Colombo' },
        { value: "+06:50", label: '(GMT +6:30) Yangon, Mandalay' },
        { value: "+07:00", label: '(GMT +7:00) Bangkok, Hanoi, Jakarta' },
        { value: "+08:00", label: '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong' },
        { value: "+08:75", label: '(GMT +8:45) Eucla' },
        { value: "+09:00", label: '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk' },
        { value: "+09:50", label: '(GMT +9:30) Adelaide, Darwin' },
        { value: "+10:00", label: '(GMT +10:00) Eastern Australia, Guam, Vladivostok' },
        { value: "+10:50", label: '(GMT +10:30) Lord Howe Island' },
        { value: "+11:00", label: '(GMT +11:00) Magadan, Solomon Islands, New Caledonia' },
        { value: "+11:50", label: '(GMT +11:30) Norfolk Island' },
        { value: "+12:00", label: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka' },
        { value: "+12:75", label: '(GMT +12:45) Chatham Islands' },
        { value: "+13:00", label: '(GMT +13:00) Apia, Nukualofa' },
        { value: "+14:00", label: '(GMT +14:00) Line Islands, Tokelau' },

    ]
}