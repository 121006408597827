import { createApp } from 'vue'

// import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Toast from "vue-toastification";
import { defineRule } from 'vee-validate';
import * as AllRules from '@vee-validate/rules';
import vSelect from "vue-select";
import VPagination from "@hennge/vue3-pagination";


import VueFeather from 'vue-feather';
import staticVars from '@/staticVars'

// import { VueTyper } from 'vue-typer'

// import { createI18n } from 'vue-i18n'
// import VueMeta from 'vue-meta'

import '../public/assets/css/font-awesome.min.css'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
// import 'aos/dist/aos.css'
import '../public/assets/css/style.css';
import '../public/assets/css/responsive.css';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js'


// import commonAllmixins from './plugins/commonAll/index';
// createApp.mixin(commonAllmixins);
//Locales
// import en from '../public/assets/locales/en.js'
//Internationalization
// const i18n = createI18n({
//   locale: 'en',
//   fallbackLocale: 'en',
//   warnHtmlInMessage: 'off',
//   messages: {
//     en,
//   },
// })
// app.use(commonAllmixins, {})

import $ from "jquery";
Object.keys(AllRules).forEach(rule => {
  defineRule(rule, AllRules[rule]);
});
const app = createApp(App)
app.use(Toast, {
  position: 'top-right',
  timeout: 3000,
  hideProgressBar: true,
  type: 'success'
});
// app.use(commonAllmixins)


//Create global variables
app.config.globalProperties.static_vars = staticVars

app.component('v-select', vSelect)
app.component('v-paginate', VPagination)
app.component(VueFeather.name, VueFeather);
// app.component('vue-typer',VueTyper)
// app.use(commonAllmixins);

// app.use(commonAllmixins, {})



app.use(store).use(router).mount('#app')
