/* eslint-disable */
import { Const } from '../lib/const.cls'
// import * as types from '@/types'
// import * as RxFactory from '@/store/api/rx-factory'
import * as Enpoints from '@/store/api/endpoints'
import * as COMMON from '@/store/commomFunction'
import contentListFile from '../../../../../public/assets/js/content.js'
// import moment from 'moment'
/*eslint no-unused-vars: "error"*/
export const actions = {
    /*[Const.RUN_GET_PROFILE_INFO]({ commit, state, getters, dispatch }, credentials) {
        COMMON._executeApi(Enpoints.EP_GET_PROFILE_INFO, credentials, { 'Authorization': 'Bearer ' + getters.GET_TOKEN }, commit, (error, result) => {
            if (!error) {
                commit(Const.SET_PROFILE_INFO, result.res_data[0])
            } else {
                COMMON._messageDisplayFunction(credentials.localParams.parameter, result.res_data, 'error')
            }
        })
    },*/
    [Const.RUN_GET_COUNTRY]({ commit, state, getters, dispatch }, credentials) {
        COMMON._executeApi(Enpoints.EP_GET_COUNTRY, credentials, { 'Authorization': 'Bearer ' + getters.GET_TOKEN }, commit, (error, result) => {
            if (!error) {
                commit(Const.SET_COUNTRY, result.country)
                credentials.localParams.parameter.changeEntity(result.entity)
            } else {
                credentials.localParams.parameter.changeEntity('')
                COMMON._messageDisplayFunction(credentials.localParams.parameter, result, 'error')
            }
        })
    },
    [Const.RUN_GET_CHART_DATA]({ commit, state, getters, dispatch }, credentials) {
        COMMON._executeApi(Enpoints.EP_GET_CHART_DATA, credentials, { 'Authorization': 'Bearer ' + getters.GET_TOKEN }, commit, (error, result) => {
            if (!error && Array.isArray(result.res_data)) {
                if (credentials.localParams.type == 'day') {
                    credentials.localParams.parameter.oneDayData = result.res_data[0] || {}
                }
                if (credentials.localParams.type == 'year') {
                    console.log(result.res_data)
                    /*let high=0,low=0;
                    if(result.res_data.length){
                        result.res_data.forEach(val=>{
                            if(high< val.high){ high = val.high; }
                            if(low == 0 || low > val.low){ low = val.low; }
                        })
                    }*/
                    credentials.localParams.parameter.prevYearData = { high, low }
                }
                // commit(Const.SET_ACTIVE_PRICE_ALERT, result.res_data.sort((a,b)=>  b.AlertID - a.AlertID) )
            } else {
                COMMON._messageDisplayFunction(credentials.localParams.parameter, result.res_data, 'error')
            }
        })
    },
    [Const.RUN_GET_FAQS_LIST]({ commit, state, getters, dispatch }, credentials) {

        COMMON._executeApi(Enpoints.EP_GET_FAQS_LISTING, credentials, { 'Authorization': 'Bearer ' + getters.GET_TOKEN }, commit, (error, result) => {
            let page = ""
            if(typeof credentials.globalParams.page != "undefined" && credentials.globalParams.page != null && credentials.globalParams.page != '' && credentials.globalParams.page == 'detail'){
                page = credentials.globalParams.page
                delete credentials.globalParams.page    
            }
            if (!error) {
                commit(Const.SET_FAQS_LIST, result.res_data)
                if(page == 'detail'){                                                
                    setTimeout(function(){                             
                        credentials.localParams.parameter.articlesInfo(credentials.localParams.parameter.$route.params.article)
                    }, 500);                        
                }
            } else {
                COMMON._messageDisplayFunction(credentials.localParams.parameter, result.res_data, 'error')
            }
        })
    },
    [Const.RUN_GET_HOOPS_DATA]({ commit, state, getters, dispatch }, credentials) {
        let hoops_data = [];
        let hoops_dataJson = getters[Const.GET_HOOPS_DATA]
        COMMON._executeApi(Enpoints.EP_GET_HOOPS_DATA, credentials, { 'Authorization': 'Bearer ' + getters.GET_TOKEN }, commit, (error, result) => {
            if (!error) {
                if(result.res_data.length > 0){
                    if (parseInt(credentials.localParams.parameter.page) > 1) {
                        if (hoops_dataJson.totalRecords) {
                            hoops_dataJson['totalRecords'] = result.res_data[0].records.length + hoops_dataJson['totalRecords']
                            hoops_dataJson['records'] = hoops_dataJson['records'].concat(result.res_data[0].records)
                        } else {
                            hoops_dataJson['totalRecords'] = result.res_data[0].records.length
                            hoops_dataJson['records'] = result.res_data[0].records
                        }
                        commit(Const.SET_HOOPS_DATA, hoops_dataJson)
                    }else{
                        commit(Const.SET_HOOPS_DATA, result.res_data[0])
                    }
                }else{
                    commit(Const.SET_HOOPS_DATA, [])
                }
            } else {
                commit(Const.SET_HOOPS_DATA, [])
                COMMON._messageDisplayFunction(credentials.localParams.parameter, result.res_data, 'error')
            }
        })
    },
    [Const.RUN_GET_CURRENT_NEWS]({ commit, state, getters, dispatch }, credentials) {
        let hoops_dataJson = getters[Const.GET_CURRENT_NEWS]
        COMMON._executeApi(Enpoints.EP_GET_CURRENT_NEWS, credentials, { 'Authorization': 'Bearer ' + getters.GET_TOKEN }, commit, (error, result) => {
            if (!error) {
                if(result.res_data.length > 0){
                    if(result.res_data.length < 10){
                        credentials.localParams.parameter.showLoadMore = true
                        if(hoops_dataJson.length > 0){
                            hoops_dataJson = hoops_dataJson.concat(result.res_data)
                            commit(Const.SET_CURRENT_NEWS, hoops_dataJson)
                        }else{
                            commit(Const.SET_CURRENT_NEWS,hoops_dataJson)
                        }
                    }else{
                        if (parseInt(credentials.localParams.parameter.page) > 1) {
                            if(hoops_dataJson.length > 0){
                                hoops_dataJson = hoops_dataJson.concat(result.res_data)
                                commit(Const.SET_CURRENT_NEWS, hoops_dataJson)
                            }else{
                                commit(Const.SET_CURRENT_NEWS,hoops_dataJson)
                            }
                        }else{
                            commit(Const.SET_CURRENT_NEWS, result.res_data)
                        }   
                        credentials.localParams.parameter.showLoadMore = false
                    }
                }else{
                    if(parseInt(credentials.localParams.parameter.page) == 1){
                        commit(Const.SET_CURRENT_NEWS, {})
                    }else{
                        if(result.res_data.length == 0 || result.res_data.length < 10){
                            credentials.localParams.parameter.showLoadMore = true
                        }
                    }
                }
            } else {
                commit(Const.SET_CURRENT_NEWS, {})
                COMMON._messageDisplayFunction(credentials.localParams.parameter, result.res_data, 'error')
            }
        })
    },
    [Const.RUN_GET_CRYPTO_NEWS]({ commit, state, getters, dispatch }, credentials) {
        let hoops_dataJson = getters[Const.GET_CRYPTO_NEWS]
        COMMON._executeApi(Enpoints.EP_GET_CURRENT_NEWS, credentials, { 'Authorization': 'Bearer ' + getters.GET_TOKEN }, commit, (error, result) => {
            if (!error) {
                if(result.res_data.length > 0){
                    if(result.res_data.length < 10){
                        credentials.localParams.parameter.showLoadMore = true
                        if(hoops_dataJson.length > 0){
                            hoops_dataJson = hoops_dataJson.concat(result.res_data)
                            commit(Const.SET_CRYPTO_NEWS, hoops_dataJson)
                        }else{
                            commit(Const.SET_CRYPTO_NEWS,hoops_dataJson)
                        }
                    }else{
                        if (parseInt(credentials.localParams.parameter.page) > 1) {
                            if(hoops_dataJson.length > 0){
                                hoops_dataJson = hoops_dataJson.concat(result.res_data)
                                commit(Const.SET_CRYPTO_NEWS, hoops_dataJson)
                            }else{
                                commit(Const.SET_CRYPTO_NEWS,hoops_dataJson)
                            }
                        }else{
                            commit(Const.SET_CRYPTO_NEWS, result.res_data)    
                        }
                        credentials.localParams.parameter.showLoadMore = false
                    }
                }else{
                    if(parseInt(credentials.localParams.parameter.page) == 1){
                        commit(Const.SET_CRYPTO_NEWS, {})
                    }else{
                        if(result.res_data.length == 0 || result.res_data.length < 10){
                            credentials.localParams.parameter.showLoadMore = true
                        }
                    }
                }
            } else {
                commit(Const.SET_CRYPTO_NEWS, {})
                COMMON._messageDisplayFunction(credentials.localParams.parameter, result.res_data, 'error')
            }
        })
    },

    [Const.RUN_CONTACT_US]({ commit, state, getters, dispatch }, credentials) {
        COMMON._executeApi(Enpoints.EP_CONTACT_US, credentials, { 'Authorization': 'Bearer ' + getters.GET_TOKEN }, commit, (error, result) => {
            if (!error) {
                credentials.localParams.parameter.reset()
                credentials.localParams.parameter.errorMsg = 'Thank you for contacting us, we will get back to you soon.'
                credentials.localParams.parameter.msgType = 'success'
            } else {
                credentials.localParams.parameter.errorMsg = result.res_data
                credentials.localParams.parameter.msgType = 'error'
                //COMMON._messageDisplayFunction(credentials.localParams.parameter, result.res_data, 'error')
            }
        })
    },

    [Const.RUN_GET_WITH_DEPO_CUR_LIST]({ commit, state, getters, dispatch }, credentials) {
        COMMON._executeApi(Enpoints.EP_GET_WITH_DEPO_CUR_LIST, credentials, '', commit, (error, result) => {
            // console.log(error, result)
            if (!error) {
                console.log('====',result.res_data, Object.keys(result.res_data).length)
                if(Object.keys(result.res_data).length > 0){
                    commit(Const.SET_WITH_DEPO_CUR_LIST, result.res_data)
                }else{
                    commit(Const.SET_WITH_DEPO_CUR_LIST, {'payment':[], 'conversion':[]})
                }
            } else {
                commit(Const.SET_WITH_DEPO_CUR_LIST,  {'payment':[], 'conversion':[]})
                COMMON._messageDisplayFunction(credentials.localParams.parameter, result.res_data, 'error')
            }
        })
    },
}
/*function formatDate(val){
    let date = '';
    try{
        date += val.slice(0, 4) //YYYY
        date += "-"+val.slice(4, 6) //MM
        date += "-"+val.slice(6, 8) //DD
        date += "T"+val.slice(8, 10) //HH
        date +=":"+val.slice(10, 12) //mm
        date +=":"+val.slice(12, 14) //ss
    }catch(e){}
    return date
}
function _sortObjectByKeys(o) {
    return Object.keys(o).sort().reduce((r, k) => (r[k] = o[k], r), {});
}*/