export const GLOBAL_BASE_URL = process.env.VUE_APP_API_BASE_URL
export const BASE_URL_FOR_CURRENCIES = process.env.VUE_APP_API_BASE_URL_FOR_CURRENCIES
//===========================================store function api=========================================
export const EP_GET_COUNTRIES_LIST = `${GLOBAL_BASE_URL}/client_country_list.js`
export const EP_GET_CHART_DATA = 'https://devpricefeed.capitalwallet.com:2087/api/provider/charting'
// export const EP_GET_CHART_DATA = process.env.VUE_CHART_DATA_URL
// export const EP_GET_FAQS_LISTING = `${GLOBAL_BASE_URL}/api/blog/category/list`
export const EP_GET_FAQS_LISTING = `${GLOBAL_BASE_URL}/api/blog/categories`
export const EP_GET_HOOPS_DATA = `${GLOBAL_BASE_URL}/api/market/insight/list`
export const EP_GET_CURRENT_NEWS = `${GLOBAL_BASE_URL}/get/news`
export const EP_CONTACT_US = `${GLOBAL_BASE_URL}/contact/us`
export const EP_GET_WITH_DEPO_CUR_LIST = `${GLOBAL_BASE_URL}/api/web/currencies`
export const EP_GET_COUNTRY = `${GLOBAL_BASE_URL}/get/geo`