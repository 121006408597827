export const state = {
    topGainers:{},
    faqslist:{},
    hoopsData : [],
    newsData : {},
    cryptoNewsData : {},
    withDepoCurList: [],
    contentlist : {},
    entityName : '',
    getCountry : '',
    entityPath : ''
}