import Vue from 'vue'
import { Const } from './provider/lib/const.cls'
// import * as Types from '../../types'
import { mapActions } from 'vuex'
import { commonAll } from './lib/commonAll.cls'
import * as commonAllStoreExtensions from './provider/'
import moment from 'moment'
import { formatPriceCommon } from '../../store/commomFunction'

/* eslint-disable */
const state = {
	...commonAllStoreExtensions.stateExt
}
const actions = {
	...commonAllStoreExtensions.actionsExt
}
const mutations = {
	...commonAllStoreExtensions.mutationsExt
}
const getters = {
	...commonAllStoreExtensions.gettersExt
}
// new store module definition
const commonAllStore = {
	state,
	actions,
	mutations,
	getters
}
// main mixin to load the new store module
export const commonAllmixins = {
	created() {
		const _store = this.$store
		if (!(_store && _store.state && _store.state[commonAll.STORE_MODULE_NAME])) {
			try {
				this.$store.registerModule(commonAll.STORE_MODULE_NAME, commonAllStore)
			} catch (error) { }
		}
	},
	methods: {
		...mapActions({
			_runGetChartData							: 			Const.RUN_GET_CHART_DATA,

			_runGetCategoriesList						: 			Const.RUN_CATEGORIES_LISTING,
			_runGetFaqsList								: 			Const.RUN_GET_FAQS_LIST,
			_runGetHoopsData							: 			Const.RUN_GET_HOOPS_DATA,
			_runGetCurrentNews							: 			Const.RUN_GET_CURRENT_NEWS,
			_runContactUs								: 			Const.RUN_CONTACT_US,
			_runGetCryptoNews							: 			Const.RUN_GET_CRYPTO_NEWS,

			_runGetWithDepoCurList				       	: 			Const.RUN_GET_WITH_DEPO_CUR_LIST,
			_runGetCountry					       		: 			Const.RUN_GET_COUNTRY,
		}),
		typeTextFunc(getData){
			const typedTextSpan = document.querySelector(".typed-text");
	        const cursorSpan = document.querySelector(".cursor");

	        const textArray = getData ;
	        const typingDelay = 100;
	        const erasingDelay = 100;
	        const newTextDelay = 1000; // Delay between current and next text
	        let textArrayIndex = 0;
	        let charIndex = 0;

	        function type() {
	          if (charIndex < textArray[textArrayIndex].length) {
	            if(!cursorSpan.classList.contains("typing")) cursorSpan.classList.add("typing");
	            typedTextSpan.textContent += textArray[textArrayIndex].charAt(charIndex);
	            charIndex++;
	            setTimeout(type, typingDelay);
	          } 
	          else {
	            cursorSpan.classList.remove("typing");
	           setTimeout(erase, newTextDelay);
	          }
	        }

	        function erase() {
	           if (charIndex > 0) {
	            if(!cursorSpan.classList.contains("typing")) cursorSpan.classList.add("typing");
	            typedTextSpan.textContent = textArray[textArrayIndex].substring(0, charIndex-1);
	            charIndex--;
	            setTimeout(erase, erasingDelay);
	          } 
	          else {
	            cursorSpan.classList.remove("typing");
	            textArrayIndex++;
	            if(textArrayIndex>=textArray.length) textArrayIndex=0;
	            setTimeout(type, typingDelay + 1100);
	          }
	        }

	        if(textArray.length){
	          setTimeout(type, newTextDelay + 250);
	        } 
		},
		pageName(val) {
			this.$parent.pageTxt = val
		},
		computedCommonFunc(getFuncName) {
			try {
				return this.$store.getters[getFuncName];
			}
			catch (ex) {
				return []
			}
		},
		formatPrice(val, isCrypto) {
			return formatPriceCommon(val, isCrypto === true ? true : false)
		},
		formatDate(value) {
			let date = moment(value)
			return date.local().format('DD MMM YYYY, h:mm:ss a')
		},
		numberFormat(value) {
			if (value) {
				var data = value
				console.log(data);
				if (!isNaN(value) && value.toString().indexOf('.') != -1) {
					// console.log('length',data.toString().split(".")[1],data.toString().split(".")[1].length)
					data = parseFloat(value).toFixed(8)
				}
				// data = parseFloat(value).toFixed(8)
				return data.toString().replace('/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g', ",");
			} else { return '' }
		},
		changeTick(name, type, varName) {
			if (type == 1) {
				this.$refs[name].stepUp()
				this[varName] = this.$refs[name].value
			} else if (type == 0) {
				if (['price', 'stop', 'limit'].includes(varName) && (this[varName] == '' || parseFloat(this[varName]) == 0)) {
					this[varName] = this.instruments[this.currency_pair].A
				} else {
					this.$refs[name].stepDown()
					this[varName] = this.$refs[name].value
				}
			}
			/*if(type == 1){
				console.log('buy',parseFloat(this[name]) + parseFloat(steps))
				this[name] = parseFloat(parseFloat(this[name]) + parseFloat(steps))
				}else if(type == 0){
				console.log('Sell',parseFloat(this[name]) - parseFloat(steps))
				if((parseFloat(this[name]) - parseFloat(steps)) <= 0){
					this[name] = 0
				}else{
				   this[name] = parseFloat(this[name]) - parseFloat(steps) 
				}
			}*/
		},
		keyUp(e, varName) {
			if (parseFloat(this[varName]) == 0 && e.key == 'ArrowDown') {
				this[varName] = this.instruments[this.currency_pair].A
			}
		},
		getRawData() {
			let data = this.rawObject
			// let data = {}
			if (!data.view_mode) {
				data['view_mode'] = { page: 'custom-view', workSpace: [] }
			}
			if (!data.view_mode.workSpace) {
				data.view_mode['workSpace'] = []
			}
			if (!data.view_mode.chart_layout) {
				data.view_mode['chart_layout'] = []
			}
			return data
		},
	},
	computed: {
		getCountry: function () { return this.computedCommonFunc(Const.GET_COUNTRY); },
		topGainers: function () { return this.computedCommonFunc(Const.GET_TOP_GAINERS); },
		faqslist: function () { return this.computedCommonFunc(Const.GET_FAQS_LIST); },
		contentlist: function () { return this.computedCommonFunc(Const.GET_CONTENT_LIST); },
		entityName: function () { 
			return this.computedCommonFunc(Const.GET_ENTITY_NAME); 
		},
		entityPath: function () { return this.computedCommonFunc(Const.GET_ENTITY_PATH); },
		hoopsData: function () { return this.computedCommonFunc(Const.GET_HOOPS_DATA); },
		newsData: function () { return this.computedCommonFunc(Const.GET_CURRENT_NEWS); },
		cryptoNewsData: function () { return this.computedCommonFunc(Const.GET_CRYPTO_NEWS); },
		// pivotData : function(){ return this.computedCommonFunc(Const.GET_PIVOT_DATA); },

		withDepoCurList : function(){ 
			return this.computedCommonFunc(Const.GET_WITH_DEPO_CUR_LIST);
		},
	}
}

export default commonAllmixins;

