export class Const {

	public static GET_TOP_GAINERS:string  = '/GET_TOP_GAINERS'
	public static SET_TOP_GAINERS:string  = '/SET_TOP_GAINERS'

	public static RUN_GET_CHART_DATA:string  = '/RUN_GET_CHART_DATA'
	public static RUN_CATEGORIES_LISTING:string  = '/RUN_CATEGORIES_LISTING'


	public static RUN_GET_FAQS_LIST:string  = '/RUN_GET_FAQS_LIST'
	public static GET_FAQS_LIST:string  = '/GET_FAQS_LIST'
	public static SET_FAQS_LIST:string  = '/SET_FAQS_LIST'

	public static RUN_GET_HOOPS_DATA:string  = '/RUN_GET_HOOPS_DATA'
	public static GET_HOOPS_DATA:string  = '/GET_HOOPS_DATA'
	public static SET_HOOPS_DATA:string  = '/SET_HOOPS_DATA'

	public static RUN_GET_CURRENT_NEWS:string  = '/RUN_GET_CURRENT_NEWS'
	public static GET_CURRENT_NEWS:string  = '/GET_CURRENT_NEWS'
	public static SET_CURRENT_NEWS:string  = '/SET_CURRENT_NEWS'

	public static RUN_GET_CRYPTO_NEWS:string  = '/RUN_GET_CRYPTO_NEWS'
	public static GET_CRYPTO_NEWS:string  = '/GET_CRYPTO_NEWS'
	public static SET_CRYPTO_NEWS:string  = '/SET_CRYPTO_NEWS'

	public static RUN_CONTACT_US:string  = '/RUN_CONTACT_US'

	public static RUN_GET_WITH_DEPO_CUR_LIST:string  = '/RUN_GET_WITH_DEPO_CUR_LIST'
	public static GET_WITH_DEPO_CUR_LIST:string  = '/GET_WITH_DEPO_CUR_LIST'
	public static SET_WITH_DEPO_CUR_LIST:string  = '/SET_WITH_DEPO_CUR_LIST'

	public static GET_CONTENT_LIST:string  = '/GET_CONTENT_LIST'
	public static SET_CONTENT_LIST:string  = '/SET_CONTENT_LIST'


	public static GET_ENTITY_NAME:string  = '/GET_ENTITY_NAME'
	public static SET_ENTITY_NAME:string  = '/SET_ENTITY_NAME'


	public static SET_ENTITY_PATH:string  = '/SET_ENTITY_PATH'
	public static GET_ENTITY_PATH:string  = '/GET_ENTITY_PATH'

	public static RUN_GET_COUNTRY:string  = '/RUN_GET_COUNTRY'
	public static GET_COUNTRY:string  = '/GET_COUNTRY'
	public static SET_COUNTRY:string  = '/SET_COUNTRY'

	
}