<template>
  <div class="topHeader whitebg" id="myHeader">
    <div class="d-flex align-items-center justify-content-between">
      <nav class="navbar navbar-expand-lg justify-content-between navbar-light w-100 p-0">
        <div class="mainLogo">
          <router-link :to="entityPath+'/'" class="router-link-active">
            <img src="/assets/images/logo.webp" alt="Header Logo" title="Capital Wallet Logo" width="200" height="46" class="img-fluid" />
          </router-link>
        </div>
        <div class="main-navbar d-flex align-items-center">
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item dropdown has-megamenu">
                <a class="nav-link dropdown-toggle is-menu" data-bs-toggle="dropdown" href="javascript:void(0)" aria-expanded="false">{{contentlist.for_business_content}} </a>
                <div class="dropdown-menu megamenu w-100" role="menu">
                  <div class="shadowAdd">
                    <div class="row align-items-start">
                      <div class="col-12 col-lg-4">
                        <h4 class="megaTitle mb-3">{{contentlist.capital_wallet_business_content}}</h4>
                        <ul class="onHover mb-3">
                          <li class="nav-item p-0">
                            <router-link :to="entityPath+'/merchant-solution'" class="nav-link d-flex">
                              <span class="borderBG d-flex align-items-center justify-content-center">
                                <span class="menuIcon icon1 flex-shrink-0"></span>
                              </span>
                              <span class="menuName ms-3">
                                <span class="semibold primarycolor">{{contentlist.merchant_solution_content}}</span>
                                <p class="mt-1">{{contentlist.header_content2}}</p>
                              </span>
                            </router-link>
                          </li>
                          <li class="nav-item p-0">
                            <router-link :to="entityPath+'/merchant-tools-plugin'" class="nav-link d-flex">
                              <span class="borderBG d-flex align-items-center justify-content-center">
                                <span class="menuIcon icon4 flex-shrink-0"></span>
                              </span>
                              <span class="menuName ms-3">
                                <span class="semibold primarycolor">{{contentlist.merchant_tools_content}}</span>
                                <p class="mt-1">{{contentlist.header_content3}}</p>
                              </span>
                            </router-link>
                          </li>
                          <li class="nav-item p-0">
                            <router-link :to="entityPath+'/pricing'" class="nav-link d-flex">
                              <span class="borderBG d-flex align-items-center justify-content-center">
                                <span class="menuIcon icon5 flex-shrink-0"></span>
                              </span>
                              <span class="menuName ms-3">
                                <span class="semibold primarycolor">{{contentlist.pricing_content}}</span>
                                <p class="mt-1">{{contentlist.header_content4}}</p>
                              </span>
                            </router-link>
                          </li>
                          <li class="nav-item p-0">
                            <router-link :to="entityPath+'/security'" class="nav-link d-flex">
                              <span class="borderBG d-flex align-items-center justify-content-center">
                                <span class="menuIcon icon9 flex-shrink-0"></span>
                              </span>
                              <span class="menuName ms-3">
                                <span class="semibold primarycolor">{{contentlist.security_content}}</span>
                                <p class="mt-1">{{contentlist.header_content5}}</p>
                              </span>
                            </router-link>
                          </li>
                        </ul>
                      </div>
                      <div class="col-12 col-lg-4">
                        <h4 class="megaTitle mb-3">{{contentlist.header_content6}}</h4>
                        <ul class="onHover mb-3">
                          <li class="nav-item p-0">
                            <router-link :to="entityPath+'/crypto-conversion'" class="nav-link d-flex">
                              <span class="borderBG d-flex align-items-center justify-content-center">
                                <span class="menuIcon icon2 flex-shrink-0"></span>
                              </span>
                              <span class="menuName ms-3">
                                <span class="semibold primarycolor">{{contentlist.conversions_content}}</span>
                                <p class="mt-1">{{contentlist.header_content7}}</p>
                              </span>
                            </router-link>
                          </li>
                          <li class="nav-item p-0">
                            <router-link :to="entityPath+'/payout'" class="nav-link d-flex">
                              <span class="borderBG d-flex align-items-center justify-content-center">
                                <span class="menuIcon icon3 flex-shrink-0"></span>
                              </span>
                              <span class="menuName ms-3">
                                <span class="semibold primarycolor">{{contentlist.payouts_content}}</span>
                                <p class="mt-1">{{contentlist.header_content8}}</p>
                              </span>
                            </router-link>
                          </li>
                        </ul>
                      </div>
                      <div class="col-12 col-lg-4 onWallet mt-0 mt-lg-5">
                        <h6 class="semibold">{{contentlist.header_content9}}</h6>
                        <p class="mt-1">{{contentlist.header_content10}}</p>
                        <router-link :to="entityPath+'/about-us'" class="secondarycolor semibold d-flex align-items-center mt-3 learnMore text-decoration-none">
                          <vue-feather size="18px" type="chevron-right"></vue-feather>{{contentlist.header_content11}}</router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="entityPath+'/supported-coin'" aria-expanded="false">{{contentlist.supported_coins_content}}</router-link>
              </li>
              <li class="nav-item">
                <a class="nav-link dropdown-toggle is-menu" data-bs-toggle="dropdown" href="javascript:void(0)" aria-expanded="false">{{contentlist.resources_content}}</a>
                <div class="dropdown-menu megamenu w-100" role="menu">
                  <div class="shadowAdd">
                    <div class="row align-items-start">
                      <div class="col-12 mb-3">
                        <h4 class="megaTitle">{{contentlist.header_content12}}</h4>
                      </div>
                      <div class="col-12 col-lg-4">
                        <ul class="onHover mb-3">
                          <li class="nav-item p-0">
                            <a href="https://blog.capitalwallet.com/" target="_blank" class="nav-link d-flex align-items-center">
                              <span class="borderBG d-flex justify-content-center align-items-center">
                                <span class="menuIcon icon6 flex-shrink-0"></span>
                              </span>
                              <span class="menuName ms-3">
                                <span class="semibold">{{contentlist.the_insider_content}}</span>
                                <p class="mt-1">{{contentlist.header_content13}}</p>
                              </span>
                            </a>
                          </li>
                          <li class="nav-item p-0">
                            <a href="https://apidocs.capitalwallet.com/" target="_blank" class="nav-link d-flex align-items-center">
                              <span class="borderBG d-flex justify-content-center align-items-center">
                                <span class="menuIcon icon7 flex-shrink-0"></span>
                              </span>
                              <span class="menuName ms-3">
                                <span class="semibold">{{contentlist.api_documentation_content}}</span>
                                <p class="mt-1">{{contentlist.header_content14}}</p>
                              </span>
                            </a>
                          </li>
                          <li class="nav-item p-0">
                            <router-link :to="entityPath+'/help-centre'" class="nav-link d-flex align-items-center">
                              <span class="borderBG d-flex justify-content-center align-items-center">
                                <span class="menuIcon icon8 flex-shrink-0"></span>
                              </span>
                              <span class="menuName ms-3">
                                <span class="semibold">{{contentlist.help_centre_content}}</span>
                                <p class="mt-1">{{contentlist.header_content15}}</p>
                              </span>
                            </router-link>
                          </li>
                        </ul>
                      </div>
                      <div class="col-12 col-lg-4"></div>
                      <div class="col-12 col-lg-4 onWallet">
                        <h6 class="semibold">{{contentlist.header_content9}}</h6>
                        <p class="mt-1">{{contentlist.header_content10}}</p>
                        <router-link :to="entityPath+'/about-us'" class="secondarycolor semibold d-flex align-items-center mt-3 learnMore text-decoration-none">
                          <vue-feather size="19px" type="chevron-right"></vue-feather>{{contentlist.header_content11}}</router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="entityPath+'/contact-us'" aria-expanded="false">{{contentlist.contact_us_content}}</router-link>
              </li>
            </ul>
            <ul class="navbar-nav d-lg-none mt-2 pt-3">
              <li class="nav-item onlyMobile"> 
                <a class="nav-link" :href="(entityName != '') ? static_vars.merchantPortalLinks[entityName].login : ''">{{contentlist.login_content}}</a>
              </li>
              <li class="nav-item pe-0 onlyMobile">
                <a class="nav-link" :href="(entityName != '') ? static_vars.merchantPortalLinks[entityName].register : ''">{{contentlist.sign_up_content}}</a>
              </li>
            </ul>
          </div>
          <div class="navbar-light rightLogin onlyDesktop">
            <ul class="navbar-nav d-flex align-items-center mb-0">
              <li class="nav-item">
                <a class="nav-link" :href="(entityName != '') ? static_vars.merchantPortalLinks[entityName].login : ''">{{contentlist.login_content}}</a>
              </li>
              <li class="nav-item pe-0">
                <a class="nav-link themeBtn small" :href="(entityName != '') ? static_vars.merchantPortalLinks[entityName].register : ''">{{contentlist.sign_up_content}}</a>
              </li>
            </ul>
          </div>
          <!-- <div class="headerCountry position-relative ms-3">
            <a @click.prevent="headercountry ^= true" href="javascript:void(0)" class="d-flex align-items-center">
                <img src="/assets/images/flag/english.png" alt="Language" class="d-block me-2" width="24" height="24" />
                
                <img src="/assets/images/down-arrow.webp" alt="Arrow" class="d-block" width="14" height="8" />
            </a>
            <ul class="dropdown_menu" :class="{ 'show': headercountry }">
              <li>
                <a href="javascript:void(0)" class="d-flex align-items-center">
                  <img src="/assets/images/flag/english.png" alt="Language" class="d-block me-2" width="24" height="24" />
                  <p class="medium themecolor mb-0 text-left">English</p>
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" class="d-flex align-items-center">
                  <img src="/assets/images/flag/poland.png" alt="Language" class="d-block me-2" width="24" height="24" />
                  <p class="medium themecolor mb-0 text-left">Poland</p>
                </a>
              </li>
              
              
            </ul>
          </div> -->
        </div>
      </nav>
    </div>
  </div>
</template>
<script>
import { commonAllmixins } from '@/plugins/commonAll'   //anamica
  export default {
    mixins: [ commonAllmixins],   //anamica
    data() {
      return {
        headercountry: false,
      }
    },
    mounted() {
      var header = document.getElementById("myHeader");
      window.addEventListener('scroll', () => {
        if (window.pageYOffset > header.offsetTop) {
          header.classList.add("sticky");
        } else {
          header.classList.remove("sticky");
        }
      });
    },
  }
</script>