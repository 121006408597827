/* eslint-disable */
// import Rx from 'rxjs/Rx'
/*import VueRx from 'vue-rx'
import Vue from 'vue'
Vue.use(VueRx, Rx)*/
import axios from 'axios'

/**
 * post data to direct express facade 
 * in json format. 
 * from express facade to mysql backend 
 * the request's content-type will be changed into 
 * application/x-www-form-urlencoded 
 * and the Json object sringified and saved under 
 * the '_x' attribute in the request to 
 * come around IE 11 bug
 */
const defaultPOSTHeader = {
  common: {
    'Content-Type': 'application/json;charset=utf-8',
    'Origin': 'https://localhost:8080'
  }
}
const defaultGETHeader = {
  common: {
    'Origin': 'https://localhost:8080'
  }
}

/**
 * - params must be passed as object literals
 * - headers must be passed in the format
 *    {
 *        'Authorization': 'Bearer 5r67nr67r67r67n'
 *    }
 * 
 * @param {*} url 
 * @param {*} headers 
 * @param {*} params 
 */
export const post = (url, params, headers) => {
  params = (! params) ? {} : params
  delete params.router
  axios.defaults.headers.common = defaultPOSTHeader
  if (headers) {
    for (let h in headers) {
      axios.defaults.headers.common[h] = headers[h]
    }
	}
  // return Rx.Observable.fromPromise(
  //   axios.post(url, params)
  // )
  // return Rx.Observable.fromPromise(

  console.log('11111111====',axios.defaults.headers)
  return new Promise((resolve, reject) => {
    axios.post(url, params)
    .then(response => { 
      resolve(response)
      // return response;
      // console.log('api-data',response)
    })
    .catch(error => {
      // console.log('api-error',error,url, params);
      reject({data:{"info":"NOK","msg":{"code":"TECH_ERROR","msg":"Some Technical issue"},"data":{},"errors":error}});
      // return {data:{"info":"NOK","msg":{"code":"TECH_ERROR","msg":"Some Technical issue"},"data":{},"errors":error}};
      //
    })
  })
  // )

}

/**
 * - params must be passed as object literals
 * - headers must be passed in the format
 *    {
 *        'Authorization': 'Bearer 5r67nr67r67r67n'
 *    }
 * 
 * @param {*} url 
 * @param {*} headers 
 * @param {*} params 
 */
export const get = (url, params, headers) => {
  params = (! params) ? {} : params
  // set default headers
  axios.defaults.headers.common = defaultGETHeader  
  if (headers) {
    for (let h in headers) {
      // add extra headers
      axios.defaults.headers.common[h] = headers[h]
    }
  }
  // return Rx.Observable.fromPromise(
  return new Promise((resolve, reject) => {
    axios.get(url,
      {params}
    ).then(response => { 
      resolve(response);
      // return response;
      // console.log('api-data',response)
    })
    .catch(error => {
      reject({data:{"info":"NOK","msg":{"code":"TECH_ERROR","msg":"Some Technical issue"},"data":{},"errors":error}});
      // return {data:{"info":"NOK","msg":{"code":"TECH_ERROR","msg":"Some Technical issue"},"data":{},"errors":error}};
      //
    })
  })
  // )
}

/**
 * load json resource from local or remote url
 * @param {*} url 
 * @param {*} headers 
 * @param {*} params 
 */
/*export const getJson = (url, headers, params) => {
  params = (! params) ? {} : params
  // set default headers
  axios.defaults.headers.common = defaultGETHeader  
  if (headers) {
    for (let h in headers) {
      // add extra headers
      axios.defaults.headers.common[h] = headers[h]
    }
  }
  return Rx.Observable.fromPromise(
    axios.get(url,
      {
        data: params
      }
    )
  )
}*/
