module.exports = [
  {
    '': {
      'PAGE NAME': 'Homepage',
      'URL': 'https://dev.finvasia.com:8998/',
      'META_TITLE': 'Capital Wallet | One Stop Crypto Payment Solution',
      'Title Length': 49,
      'META_DESCRIPTION':
        'Grow your business with an award-winning crypto payment gateway to globally accept and manage bitcoin and other cryptocurrencies including stablecoins',
      'Description Length': 150,
      'META_KEYWORDS':
        'Crypto Payment Solution, capital wallet, capitalwallet, crypto payments, accept cryptocurrency, award-winning crypto payment gateway, bitcoin payments, litecoin payments, bitcoin payment gateway, litecoin payment gateway, accept litecoin, altcoin payments, dOGecoin payments, accept bitcoin, dOGecoin, ethereum',
      'META_H1': 'Accept Crypto Payments With Ease - Power Up Your Business',
      'META_H2': 'Convert Cryptocurrencies Instantly',
      'OG': {
        'locale': 'en_US',
        'site_name': 'Capital Wallet | One Stop Crypto Payment Solution',
        'type': 'website',
        'title': 'One Stop Crypto Payment Solution',
        'description':
          'Grow your business with an award-winning crypto payment gateway to globally accept and manage bitcoin and other cryptocurrencies including stablecoins',
        'URL': 'https://dev.finvasia.com:8998/',
        'image':
          'https://capitalwallet-images.s3.eu-west-1.amazonaws.com/CW_Images/home-page.webp',
      },
      'TWITTER': {
        'card': 'summary_large_image',
        'site': '@Capitalwallet_',
        'title': 'Capital Wallet | One Stop Crypto Payment Solution',
        'description':
          'Grow your business with an award-winning crypto payment gateway to globally accept and manage bitcoin and other cryptocurrencies including stablecoins',
        'creator': '@Capitalwallet_',
        'image':
          'https://capitalwallet-images.s3.eu-west-1.amazonaws.com/CW_Images/home-page.webp',
      },
    },
    'merchant-solution': {
      'PAGE NAME': 'Merchant Solution',
      'URL': 'https://dev.finvasia.com:8998/merchant-solution',
      'META_TITLE': 'Capital Wallet - Accept BTC Payments at Your Business',
      'Title Length': 53,
      'META_DESCRIPTION':
        'Expand your payment options and attract new customers by accepting BTC payments at your business. Fast, secure, and easy to use.',
      'Description Length': 128,
      'META_KEYWORDS':
        'Accept Crypto Payments, accept btc payments, accept cryptos, store cryptos, convert to crypto, convert to fiat, conver to stablecoin, crypto settlement, fiat settlement, capital wallet, capitalwallet, crypto payment platform, payment gateway, award-winning crypto payment gateway',
      'META_H1': 'Accept BTC Payments At Your Business',
      'META_H2': 'The Most Reliable Payment Gateway For Every Business',
      'OG': {
        'locale': 'en_US',
        'site_name': 'Accept BTC Payments at Your Business',
        'type': 'website',
        'title': 'Capital Wallet - Accept BTC Payments at Your Business',
        'description':
          'Expand your payment options and attract new customers by accepting BTC payments at your business. Fast, secure, and easy to use.',
        'URL': 'https://dev.finvasia.com:8998/merchant-solution',
        'image':
          'https://capitalwallet-images.s3.eu-west-1.amazonaws.com/CW_Images/merchant-solutions-page.webp',
      },
      'TWITTER': {
        'card': 'summary_large_image',
        'site': '@Capitalwallet_',
        'title': 'Capital Wallet - Accept BTC Payments at Your Business',
        'description':
          'Expand your payment options and attract new customers by accepting BTC payments at your business. Fast, secure, and easy to use.',
        'creator': '@Capitalwallet_',
        'image':
          'https://capitalwallet-images.s3.eu-west-1.amazonaws.com/CW_Images/merchant-solutions-page.webp',
      },
    },
    'crypto-conversion': {
      'PAGE NAME': 'Crypto Conversion',
      'URL': 'https://dev.finvasia.com:8998/crypto-conversion',
      'META_TITLE': 'Capital Wallet - Simplifying Crypto Conversion process',
      'Title Length': 54,
      'META_DESCRIPTION':
        'Streamline Crypto Conversions with Capital Wallet. Effortlessly accept and convert your digital assets for everyday transactions.',
      'Description Length': 129,
      'META_KEYWORDS':
        'Crypto Conversion, convert to crypto, convert to fiat, convert to stablecoin, crypto settlement, fiat settlement, capital wallet, capitalwallet, auto conversion, real time conversion',
      'META_H1': 'Tame Market Volatility with Crypto Conversion',
      'META_H2': 'Conversion types Suitable For Every Business Need',
      'OG': {
        'locale': 'en_US',
        'site_name': 'Simplifying Crypto Conversion process',
        'type': 'website',
        'title': 'Capital Wallet - Simplifying Crypto Conversion process',
        'description':
          'Streamline Crypto Conversions with Capital Wallet. Effortlessly accept and convert your digital assets for everyday transactions.',
        'URL': 'https://dev.finvasia.com:8998/crypto-conversion',
        'image':
          'https://capitalwallet-images.s3.eu-west-1.amazonaws.com/CW_Images/crypto-conversion-page.webp',
      },
      'TWITTER': {
        'card': 'summary_large_image',
        'site': '@Capitalwallet_',
        'title': 'Capital Wallet - Simplifying Crypto Conversion process',
        'description':
          'Streamline Crypto Conversions with Capital Wallet. Effortlessly accept and convert your digital assets for everyday transactions.',
        'creator': '@Capitalwallet_',
        'image':
          'https://capitalwallet-images.s3.eu-west-1.amazonaws.com/CW_Images/crypto-conversion-page.webp',
      },
    },
    'payout': {
      'PAGE NAME': 'Payout',
      'URL': 'https://dev.finvasia.com:8998/payout',
      'META_TITLE': 'Capital Wallet - Payouts Made Easy',
      'Title Length': 34,
      'META_DESCRIPTION':
        'Takout your crypto payments into stablecoins or fiat and retain the value of the payments you receive with Capital Wallet.',
      'Description Length': 123,
      'META_KEYWORDS':
        'Crypto Payouts, instant payouts, manual settlement, auto settlement, crypto conversion, convert to crypto, convert to fiat, conver to stablecoin, crypto settlement, fiat settlement, capital wallet, capitalwallet, auto conversion, real time conversion, capital wallet payouts',
      'META_H1': 'Payouts Made Easy',
      'META_H2': 'A Global Payouts Solution\nFlexible Settlement Methods',
      'OG': {
        'locale': 'en_US',
        'site_name': 'Payouts Made Easy',
        'type': 'website',
        'title': 'Capital Wallet - Payouts Made Easy',
        'description':
          'Takout your crypto payments into stablecoins or fiat and retain the value of the payments you receive with Capital Wallet.',
        'URL': 'https://dev.finvasia.com:8998/payout',
        'image':
          'https://capitalwallet-images.s3.eu-west-1.amazonaws.com/CW_Images/payouts-page.webp',
      },
      'TWITTER': {
        'card': 'summary_large_image',
        'site': '@Capitalwallet_',
        'title': 'Capital Wallet - Payouts Made Easy',
        'description':
          'Takout your crypto payments into stablecoins or fiat and retain the value of the payments you receive with Capital Wallet.',
        'creator': '@Capitalwallet_',
        'image':
          'https://capitalwallet-images.s3.eu-west-1.amazonaws.com/CW_Images/payouts-page.webp',
      },
    },
    'merchant-tools-plugin': {
      'PAGE NAME': 'Merchant Tools Plugin',
      'URL': 'https://dev.finvasia.com:8998/merchant-tools-plugin',
      'META_TITLE': 'Capital Wallet CMS Plugins | Crypto Payment Integration',
      'Title Length': 55,

      'META_DESCRIPTION':
        'Discover essential CMS plugins and resources for crypto merchants. Elevate your business and stay ahead in the cryptocurrency market.',
      'Description Length': 133,
      'META_KEYWORDS':
        'CMS Plugins, e-commerce, accept crypto payments, woocommerce, shopify, magento, integrating crypto payments, crypto payment gateway, btc payments, eth payments, capital wallet, capitalwallet, austrac, award-winning crypto payment gateway',
      'META_H1': 'Crypto Payment Gateway for WooCommerce',
      'META_H2': 'Didn’t Find The Right CMS Plugins?',
      'OG': {
        'locale': 'en_US',
        'site_name': 'Merchant Tools Plugin',
        'type': 'website',
        'title': 'Capital Wallet CMS Plugins | Crypto Payment Integration',
        'description':
          'Discover essential CMS plugins and resources for crypto merchants. Elevate your business and stay ahead in the cryptocurrency market.',
        'URL': 'https://dev.finvasia.com:8998/merchant-tools-plugin',
        'image':
          'https://capitalwallet-images.s3.eu-west-1.amazonaws.com/CW_Images/merchant-solutions-page.webp',
      },
      'TWITTER': {
        'card': 'summary_large_image',
        'site': '@Capitalwallet_',
        'title': 'Capital Wallet CMS Plugins | Crypto Payment Integration',
        'description':
          'Discover essential CMS plugins and resources for crypto merchants. Elevate your business and stay ahead in the cryptocurrency market.',
        'creator': '@Capitalwallet_',
        'image':
          'https://capitalwallet-images.s3.eu-west-1.amazonaws.com/CW_Images/merchant-solutions-page.webp',
      },
    },
    'pricing': {
      'PAGE NAME': 'Pricing',
      'URL': 'https://dev.finvasia.com:8998/pricing',
      'META_TITLE': 'Capital Wallet Pricing for Crypto Payments',
      'Title Length': 42,
      'META_DESCRIPTION':
        'Enjoy ultra low fees - We offer you a transparent and competitive pricing plan to help you scale up your business.',
      'Description Length': 114,
      'META_KEYWORDS':
        'Transparent Pricing, crypto fees, fees, zero fees, capital wallet, capitalwallet fees, capital wallet fees, crypto payment gateway fees, payment processing fees',
      'META_H1': 'Transparent Pricing For Any Business',
      'META_H2': 'Questions You May Have\n',
      'OG': {
        'locale': 'en_US',
        'site_name': 'Pricing for Crypto Payments',
        'type': 'website',
        'title': 'Capital Wallet Pricing for Crypto Payments',
        'description':
          'Enjoy ultra low fees - We offer you a transparent and competitive pricing plan to help you scale up your business.',
        'URL': 'https://dev.finvasia.com:8998/pricing',
        'image':
          'https://capitalwallet-images.s3.eu-west-1.amazonaws.com/CW_Images/pricing-page.webp',
      },
      'TWITTER': {
        'card': 'summary_large_image',
        'site': '@Capitalwallet_',
        'title': 'Capital Wallet Pricing for Crypto Payments',
        'description':
          'Enjoy ultra low fees - We offer you a transparent and competitive pricing plan to help you scale up your business.',
        'creator': '@Capitalwallet_',
        'image':
          'https://capitalwallet-images.s3.eu-west-1.amazonaws.com/CW_Images/pricing-page.webp',
      },
    },
    'Supported Coin': {
      'PAGE NAME': 'Supported Coin',
      'URL': 'https://dev.finvasia.com:8998/supported-coin',
      'META_TITLE': 'Unlock Crypto Opportunities | Accept, Trade, Store Cryptos',
      'Title Length': 58,
      'META_DESCRIPTION':
        'Harness popular Cryptos to scale up your Business. Accept, trade, store, and send cryptos effortlessly with our revolutionary Payment Gateway.',
      'Description Length': 143,
      'META_KEYWORDS':
        'Deposit & Withdrawal Bitcoin, Cryptocurrency payment gateway, crypto opportunities, accept crypto payments, trade cryptocurrencies, store cryptos securely, effortless crypto transactions, Bitcoin (BTC), Litecoin (LTC), Ethereum (ETH), TrueUSD (TUSD), USD Coin (USDC), Bitcoin Cash (BCH), Algorand (ALGO), DOGecoin (DOGE), Tether (USDT)',
      'META_H1': 'Unlock Limitless Crypto Opportunities',
      'META_H2': '-----',
      'OG': {
        'locale': 'en_US',
        'site_name': 'Unlock Crypto Opportunities',
        'type': 'website',
        'title': 'Unlock Crypto Opportunities | Accept, Trade, Store Cryptos',
        'description':
          'Harness popular Cryptos to scale up your Business. Accept, trade, store, and send cryptos effortlessly with our revolutionary Payment Gateway.',
        'URL': 'https://dev.finvasia.com:8998/supported-coin',
        'image':
          'https://capitalwallet-images.s3.eu-west-1.amazonaws.com/CW_Images/supported-coin-page.webp',
      },
      'TWITTER': {
        'card': 'summary_large_image',
        'site': '@Capitalwallet_',
        'title': 'Unlock Crypto Opportunities | Accept, Trade, Store Cryptos',
        'description':
          'Harness popular Cryptos to scale up your Business. Accept, trade, store, and send cryptos effortlessly with our revolutionary Payment Gateway.',
        'creator': '@Capitalwallet_',
        'image':
          'https://capitalwallet-images.s3.eu-west-1.amazonaws.com/CW_Images/supported-coin-page.webp',
      },
    },
    'the-insider': {
      'PAGE NAME': 'The Insider',
      'URL': 'https://dev.finvasia.com:8998/the-insider',
      'META_TITLE': 'The Insider - Tips, Tutorials from Capital Wallet Network',
      'Title Length': 57,
      'META_DESCRIPTION':
        'Stay updated with the latest trends in payment technolOGy. Explore product updates, upcoming events, and gain valuable blockchain education insights.',
      'Description Length': 149,
      'META_KEYWORDS':
        'Blockchain Education, Future of payment, Tips and tutorials, Data-driven insights, Success stories, Capital Wallet network, Current trends, Product updates, Events, Blockchain education, Security concerns, Privacy concerns, Crypto payments, E-commerce landscape, Payment processing, Transaction fees, Crypto Payments, capitalwallet, CapitalWallet, CryptoPayments',
      'META_H1': 'The Insider',
      'META_H2': 'Recent Articles and News',
      'OG': {
        'locale': 'en_US',
        'site_name': 'The Insider',
        'type': 'website',
        'title': 'The Insider - Tips, Tutorials from Capital Wallet Network',
        'description':
          'Stay updated with the latest trends in payment technolOGy. Explore product updates, upcoming events, and gain valuable blockchain education insights.',
        'URL': 'https://dev.finvasia.com:8998/the-insider',
        'image':
          'https://capitalwallet-images.s3.eu-west-1.amazonaws.com/CW_Images/the-insider-page.webp',
      },
      'TWITTER': {
        'card': 'summary_large_image',
        'site': '@Capitalwallet_',
        'title': 'The Insider - Tips, Tutorials from Capital Wallet Network',
        'description':
          'Stay updated with the latest trends in payment technolOGy. Explore product updates, upcoming events, and gain valuable blockchain education insights.',
        'creator': '@Capitalwallet_',
        'image':
          'https://capitalwallet-images.s3.eu-west-1.amazonaws.com/CW_Images/the-insider-page.webp',
      },
    },
    'help-centre': {
      'PAGE NAME': 'Help Centre',
      'URL': 'https://dev.finvasia.com:8998/help-centre',
      'META_TITLE': 'Crypto Payment Solution for Businesses: FAQs | Help Centre',
      'Title Length': 58,
      'META_DESCRIPTION':
        'Find answers to the frequently asked questions about our Crypto Payment Solution for Businesses.Learn how to create an account .',
      'Description Length': 128,
      'META_KEYWORDS':
        'Crypto Payments Help Centre, Crypto Payment Solution, Businesses, Frequently Asked Questions,Help Centre, Creating an Account, Managing My Account, Managing My Portal, My Digital Wallet, Privacy and Security, Integration, Support, Crypto Glossary, Cryptocurrency, Blockchain,  Crypto Payments, capitalwallet, CapitalWallet',
      'META_H1': 'Help Centre',
      'META_H2': 'Crypto Glossary',
      'OG': {
        'locale': 'en_US',
        'site_name': 'Help Centre',
        'type': 'website',
        'title': 'Crypto Payment Solution for Businesses: FAQs | Help Centre',
        'description':
          'Find answers to the frequently asked questions about our Crypto Payment Solution for Businesses.Learn how to create an account .',
        'URL': 'https://dev.finvasia.com:8998/help-centre',
        'image': '',
      },
      'TWITTER': {
        'card': 'summary_large_image',
        'site': '@Capitalwallet_',
        'title': 'Crypto Payment Solution for Businesses: FAQs | Help Centre',
        'description':
          'Find answers to the frequently asked questions about our Crypto Payment Solution for Businesses.Learn how to create an account .',
        'creator': '@Capitalwallet_',
        'image': '',
      },
    },
    'contact-us': {
      'PAGE NAME': 'Contact Us',
      'URL': 'https://dev.finvasia.com:8998/contact-us',
      'META_TITLE': 'Capital Wallet | Crypto Payments - Contact Us',
      'Title Length': 45,
      'META_DESCRIPTION':
        "Simply open a new case and send us your queries or worries, and we'll provide you with the support you require.",
      'Description Length': 111,
      'META_KEYWORDS':
        'Support Team Assistance, accept digital payments, crypto payments, Capital Wallet, lower fees, borderless transactions, no chargebacks, easy integration, sign up, support, Cryptopayments, Crypto Payments, capitalwallet, CapitalWallet',
      'META_H1': 'How Can We Help?',
      'META_H2': 'Get Replies To Your Inquiries\nQuestions You May Have',
      'OG': {
        'locale': 'en_US',
        'site_name': 'Contact Us',
        'type': 'website',
        'title': 'Capital Wallet | Crypto Payments - Contact Us',
        'description':
          "Simply open a new case and send us your queries or worries, and we'll provide you with the support you require.",
        'URL': 'https://dev.finvasia.com:8998/contact-us',
        'image':
          'https://capitalwallet-images.s3.eu-west-1.amazonaws.com/CW_Images/contact-us.webp',
      },
      'TWITTER': {
        'card': 'summary_large_image',
        'site': '@Capitalwallet_',
        'title': 'Capital Wallet | Crypto Payments - Contact Us',
        'description':
          "Simply open a new case and send us your queries or worries, and we'll provide you with the support you require.",
        'creator': '@Capitalwallet_',
        'image':
          'https://capitalwallet-images.s3.eu-west-1.amazonaws.com/CW_Images/contact-us.webp',
      },
    },

    'register': {
      'PAGE NAME': 'Register',
      'URL': 'https://client.capitalwallet.com/register',
      'META_TITLE': 'Accept Crypto Payments | Create a Capital Wallet Account',
      'Title Length': 56,
      'META_DESCRIPTION':
        'Sign up for a Capital Wallet cryptopayments account by entering your email. Read and understand our Terms of Use and Privacy Policy',
      'Description Length': 131,
      'META_KEYWORDS':
        'Enter your Email, Cryptopayments, Crypto Payments, capitalwallet, CapitalWallet, email, unique, Capital Wallet account, corporate email, account, lOG in, Terms of Use, Privacy Policy, English language',
      'META_H1': '-----',
      'META_H2': '-----',
      'OG': {
        'locale': 'en_US',
        'site_name': 'Register',
        'type': 'website',
        'title': 'Accept Crypto Payments | Create a Capital Wallet Account',
        'description':
          'Sign up for a Capital Wallet cryptopayments account by entering your email. Read and understand our Terms of Use and Privacy Policy',
        'URL': 'https://client.capitalwallet.com/register',
        'image': '',
      },
      'TWITTER': {
        'card': 'summary_large_image',
        'site': '@Capitalwallet_',
        'title': 'Accept Crypto Payments | Create a Capital Wallet Account',
        'description':
          'Sign up for a Capital Wallet cryptopayments account by entering your email. Read and understand our Terms of Use and Privacy Policy',
        'creator': '@Capitalwallet_',
        'image': '',
      },
    },
    'about-us': {
      'PAGE NAME': 'About Us',
      'URL': 'https://dev.finvasia.com:8998/about-us',
      'META_TITLE': 'Capital Wallet | Reshaping Crypto Payments for Businesses',
      'Title Length': 57,
      'META_DESCRIPTION':
        'Discover how we, a registered digital assets payment service provider, are dedicated to making crypto payments accessible to businesses worldwide.',
      'Description Length': 146,
      'META_KEYWORDS':
        'About Us Page, Digital payments, Crypto payments, Payment service provider, Cryptocurrency payment solutions, Financial freedom, Business ambitions, Secure payments, Fast payments, Convenient payments, Accessible crypto payments, Registered digital assets, Business upliftment, Crypto-ecosystem, Advanced payment solutions, Transparency and fairness, Cutting-edge technolOGy, Moving forward, Never settle, capitalwallet, Capital Wallet.',
      'META_H1':
        'We reshape the digital payments so every business can realize their ambitions',
      'META_H2': 'Our Achievements& Vision',
      'OG': {
        'locale': 'en_US',
        'site_name': 'About Us',
        'type': 'website',
        'title': 'Capital Wallet | Reshaping Crypto Payments for Businesses',
        'description':
          'Discover how we, a registered digital assets payment service provider, are dedicated to making crypto payments accessible to businesses worldwide.',
        'URL': 'https://dev.finvasia.com:8998/about-us',
        'image':
          'https://capitalwallet-images.s3.eu-west-1.amazonaws.com/CW_Images/about-us-page.webp',
      },
      'TWITTER': {
        'card': 'summary_large_image',
        'site': '@Capitalwallet_',
        'title': 'Capital Wallet | Reshaping Crypto Payments for Businesses',
        'description':
          'Discover how we, a registered digital assets payment service provider, are dedicated to making crypto payments accessible to businesses worldwide.',
        'creator': '@Capitalwallet_',
        'image':
          'https://capitalwallet-images.s3.eu-west-1.amazonaws.com/CW_Images/about-us-page.webp',
      },
    },

    'security': {
      'PAGE NAME': 'Security',
      'URL': 'https://dev.finvasia.com:8998/security',
      'META_TITLE': 'Capital Wallet | Secure Payment Gateway',
      'Title Length': 58,
      'META_DESCRIPTION':
        'With superior security standards and defense-in-depth strategy, Capital Wallet guarantee the safety of your assets.',
      'Description Length': 128,
      'META_KEYWORDS':
        'Your Security, Secure Payment Gateway, cryptopayments, Crypto Payments, Data Protection, Reliable Security, Superior Security Standards, Sophisticated Encryption, Cold Storage Wallets, Auditing and Testing, Principles of Least Privilege, 24/7 Monitoring, Defense-In-Depth Strategy, Multi-Layered Security Features, Two-Factor Authentication (2FA), Digital Currency Security, Privacy Policy, Emerging Threats Protection, capitalwallet, Capital Wallet.',
      'META_H1': 'Your Security - Our Commitment',
      'META_H2': 'Superior Security Standards. Guaranteed',
      'OG': {
        'locale': 'en_US',
        'site_name': 'Security',
        'type': 'website',
        'title': 'Capital Wallet | Secure Payment Gateway',
        'description':
          'With superior security standards and defense-in-depth strategy, Capital Wallet guarantee the safety of your assets.',
        'URL': 'https://dev.finvasia.com:8998/security',
        'image':
          'https://capitalwallet-images.s3.eu-west-1.amazonaws.com/CW_Images/security-page.webp',
      },
      'TWITTER': {
        'card': 'summary_large_image',
        'site': '@Capitalwallet_',
        'title': 'Capital Wallet | Secure Payment Gateway',
        'description':
          'With superior security standards and defense-in-depth strategy, Capital Wallet guarantee the safety of your assets.',
        'creator': '@Capitalwallet_',
        'image':
          'https://capitalwallet-images.s3.eu-west-1.amazonaws.com/CW_Images/security-page.webp',
      },
    },
    'press-release': {
      'PAGE NAME': 'Press Release',
      'URL': 'https://dev.finvasia.com:8998/press-release',
      'META_TITLE': 'Capital Wallet Press Room | Achievements and Announcements',
      'Title Length': 45,
      'META_DESCRIPTION':
        "Explore CapitalWallet's press room to stay updated on the latest achievements, news, and announcements covered by global media",
      'Description Length': 111,
      'META_KEYWORDS':
        'Press Release Room, CapitalWallet, capitalwallet,cryptopayments, Crypto Payments, Press Room, Achievements, News, Announcements, Global Coverage, Press Kit, Media Contact, Cryptocurrency, Blockchain TechnolOGy, NFTs, Leadership Changes, Crypto Payment Solutions, Awards, Product Review',
      'META_H1': 'CapitalWallet Press Room',
      'META_H2': 'Capital Wallet Recent Press',
      'OG': {
        'locale': 'en_US',
        'site_name': 'Achievements and Announcements',
        'type': 'website',
        'title': 'Capital Wallet Press Room | Achievements and Announcements',
        'description':
          "Explore CapitalWallet's press room to stay updated on the latest achievements, news, and announcements covered by global media",
        'URL': 'https://dev.finvasia.com:8998/press-release',
        'image':
          'https://capitalwallet-images.s3.eu-west-1.amazonaws.com/CW_Images/press-release-page.webp',
      },
      'TWITTER': {
        'card': 'summary_large_image',
        'site': '@Capitalwallet_',
        'title': 'Capital Wallet Press Room | Achievements and Announcements',
        'description':
          "Explore CapitalWallet's press room to stay updated on the latest achievements, news, and announcements covered by global media",
        'creator': '@Capitalwallet_',
        'image':
          'https://capitalwallet-images.s3.eu-west-1.amazonaws.com/CW_Images/press-release-page.webp',
      },
    },
    'terms-of-use': {
      'PAGE NAME': 'Terms of Use',
      'URL': 'https://dev.finvasia.com:8998/terms-of-use',
      'META_TITLE': 'Capital Wallet Terms of Use: Legal Rights and Obligations',
      'Title Length': 56,
      'META_DESCRIPTION':
        'Read the Capital Wallet Terms of Use carefully to understand your legal rights, remedies, and obligations when using the platform. ',
      'Description Length': 131,
      'META_KEYWORDS':
        'Terms of Use, Capital Wallet, capitalwallet, Crypto Payments,Terms of Use, Legal Rights, Remedies, Obligations, Terms and Conditions, AML/CTF Policy, Eligibility Criteria, Fees, Intellectual Property Rights, Financial Services, Online Wallet, Digital Payments, Secure Transactions, User Agreement, Privacy Policy, Account Registration, Withdrawal Limits, Customer Support, Mobile App, Security Measures, Payment Gateway, Transaction History, Anti-Money Laundering, Customer Identification PrOGram.',
      'META_H1': 'Terms of Use',
      'META_H2': '-----',
      'OG': {
        'locale': 'en_US',
        'site_name': 'Terms of Use',
        'type': 'website',
        'title': 'Capital Wallet Terms of Use: Legal Rights and Obligations',
        'description':
          'Read the Capital Wallet Terms of Use carefully to understand your legal rights, remedies, and obligations when using the platform. ',
        'URL': 'https://dev.finvasia.com:8998/terms-of-use',
        'image': '',
      },
      'TWITTER': {
        'card': 'summary_large_image',
        'site': '@Capitalwallet_',
        'title': 'Capital Wallet Terms of Use: Legal Rights and Obligations',
        'description':
          'Read the Capital Wallet Terms of Use carefully to understand your legal rights, remedies, and obligations when using the platform.',
        'creator': '@Capitalwallet_',
        'image': '',
      },
    },
    'aml-policy': {
      'PAGE NAME': 'AML Policy',
      'URL': 'https://dev.finvasia.com:8998/aml-policy',
      'META_TITLE': 'Capital Wallet | AML/CTF Policy',
      'Title Length': 57,
      'META_DESCRIPTION':
        "Discover Capital Wallet's comprehensive Anti-Money Laundering & Counter Terrorism Financing (AML/CTF) Policy",
      'Description Length': 146,
      'META_KEYWORDS':
        'AML/CTF POLICY, Anti-money Laundering, Counter Terrorism Financing, policy, Capital Wallet, Registered Digital Currency Exchange, Reporting Entity, AUSTRAC, measures, processes, detect, prevent, report, illegally launder money, finance illegal activities, terrorism, drug trafficking, fraud, Customer Verification, Ongoing Due Diligence, Enhanced Due Diligence, high risk, Transaction Monitoring, Reporting, Sanctions, ',
      'META_H1': 'Anti-money Laundering And Counter Terrorism Financing policy',
      'META_H2': '-----',
      'OG': {
        'locale': 'en_US',
        'site_name': 'AML Policy',
        'type': 'website',
        'title': 'Capital Wallet | AML/CTF Policy',
        'description':
          "Discover Capital Wallet's comprehensive Anti-Money Laundering & Counter Terrorism Financing (AML/CTF) Policy",
        'URL': 'https://dev.finvasia.com:8998/aml-policy',
        'image': '',
      },
      'TWITTER': {
        'card': 'summary_large_image',
        'site': '@Capitalwallet_',
        'title': 'Capital Wallet | AML/CTF Policy',
        'description':
          "Discover Capital Wallet's comprehensive Anti-Money Laundering & Counter Terrorism Financing (AML/CTF) Policy",
        'creator': '@Capitalwallet_',
        'image': '',
      },
    },
    'privacy-policy': {
      'PAGE NAME': 'Privacy Policy',
      'URL': 'https://dev.finvasia.com:8998/privacy-policy',
      'META_TITLE': 'Capital Wallet | Privacy Policy',
      'Title Length': 56,
      'META_DESCRIPTION':
        'Read our Privacy Policy to understand how Capital Wallet collects, uses, and protects your personal information. ',
      'Description Length': 131,
      'META_KEYWORDS':
        'Privacy Policy, Personal Data, Data Protection, Consent, Confidentiality, Information Security, Privacy Rights, User Privacy, Privacy Notice, Data Privacy, Privacy Regulations, Privacy Compliance, Privacy Standards, Privacy Practices, Privacy Statement, capitalwallet, capital wallet',
      'META_H1': 'Privacy Policy',
      'META_H2': '-----',
      'OG': {
        'locale': 'en_US',
        'site_name': 'Privacy Policy',
        'type': 'website',
        'title': 'Capital Wallet | Privacy Policy',
        'description':
          'Read our Privacy Policy to understand how Capital Wallet collects, uses, and protects your personal information. ',
        'URL': 'https://dev.finvasia.com:8998/privacy-policy',
        'image': '',
      },
      'TWITTER': {
        'card': 'summary_large_image',
        'site': '@Capitalwallet_',
        'title': 'Capital Wallet | Privacy Policy',
        'description':
          'Read our Privacy Policy to understand how Capital Wallet collects, uses, and protects your personal information. ',
        'creator': '@Capitalwallet_',
        'image': '',
      },
    },
    'cookies-policy': {
      'PAGE NAME': 'AML Policy',
      'URL': 'https://dev.finvasia.com:8998/cookies-policy',
      'META_TITLE': 'Cookies: Enhancing Your Website Experience',
      'Title Length': 57,
      'META_DESCRIPTION':
        'Discover how Capital Wallet utilizes cookies to personalize your browsing experience. The Cookie Policy explains the types of cookies we use.',
      'Description Length': 146,
      'META_KEYWORDS':
        'Cookies, Cookie Policy, Cookies Definition, Delete cookies, Block cookies, Disable cookies, Accept cookies, Decline cookies, Web browser settings, Session cookies, Persistent cookies, Third-party cookies, Web beacons, Tracking cookies, Advertising cookies, User preferences, Website usage analysis, Privacy policy, Security measures, Information providers, capitalwallet, capital wallet',
      'META_H1': 'Cookies',
      'META_H2': '-----',
      'OG': {
        'locale': 'en_US',
        'site_name': 'AML Policy',
        'type': 'website',
        'title': 'Cookies: Enhancing Your Website Experience',
        'description':
          'Discover how Capital Wallet utilizes cookies to personalize your browsing experience. The Cookie Policy explains the types of cookies we use.',
        'URL': 'https://dev.finvasia.com:8998/cookies-policy',
        'image': '',
      },
      'TWITTER': {
        'card': 'summary_large_image',
        'site': '@Capitalwallet_',
        'title': 'Cookies: Enhancing Your Website Experience',
        'description':
          'Discover how Capital Wallet utilizes cookies to personalize your browsing experience. The Cookie Policy explains the types of cookies we use.',
        'creator': '@Capitalwallet_',
        'image': '',
      },
    },
  },
];
