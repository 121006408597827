<template>
  <router-view v-show="entityName != '' " />
</template>

<script>
// import { ProviderCacheMixins } from './plugins/vue-provider-cache/mixin.ts'
import { commonAllmixins } from './plugins/commonAll'
// import { Const } from '@/plugins/vue-provider-cache/const.mod'
// import crypto_list from '../public/assets/js/crypto-list.json'
import seoData from '../public/assets/js/seo.js'
import contentListFile from '../public/assets/js/content.js'
import { Const } from '@/plugins/commonAll/provider/lib/const.cls.ts'
export default {
  mixins: [ commonAllmixins],
  data() {
      return {
    pageTitle: '',
    pageDescription: '',
    pageKeywords: '',
    countryJson : ['AU'],
    getEntity : '',
    // entityName : ''
    // getEntityFromUrl : ''
      }
  },
 
  watch:{
    '$route'(){
      if(this.$route.path){
        
        let seoJson = {}
        seoJson = seoData[0]

        let key = this.$route.path.split('/')[2]
        // this.getEntityFromUrl = this.$route.path.split('/')[1]
        let getSeoPath = seoJson[key]
        if(key == undefined || seoJson[key] == undefined ){
          getSeoPath = seoJson[""]
        }
        if(getSeoPath){
          document.title = getSeoPath.META_TITLE;
          document.querySelector('meta[name="description"]').setAttribute("content", getSeoPath.META_DESCRIPTION);
          document.querySelector('meta[name="keywords"]').setAttribute("content", getSeoPath.META_KEYWORDS);

          document.querySelector('meta[name="og_locale"]').setAttribute("content", getSeoPath.OG.locale);
          document.querySelector('meta[name="og_site_name"]').setAttribute("content", getSeoPath.OG.site_name);
          document.querySelector('meta[name="og_type"]').setAttribute("content", getSeoPath.OG.type);
          document.querySelector('meta[name="og_title"]').setAttribute("content", getSeoPath.OG.title);
          document.querySelector('meta[name="og_description"]').setAttribute("content", getSeoPath.OG.description);
          document.querySelector('meta[name="og_url"]').setAttribute("content", getSeoPath.OG.URL);
          document.querySelector('meta[name="og_image"]').setAttribute("content", getSeoPath.OG.image);

          document.querySelector('meta[name="twitter:card"]').setAttribute("content", getSeoPath.TWITTER.card);
          document.querySelector('meta[name="twitter:site"]').setAttribute("content", getSeoPath.TWITTER.site);
          document.querySelector('meta[name="twitter:title"]').setAttribute("content", getSeoPath.TWITTER.title);
          document.querySelector('meta[name="twitter:description"]').setAttribute("content", getSeoPath.TWITTER.description);
          document.querySelector('meta[name="twitter:creator"]').setAttribute("content", getSeoPath.TWITTER.creator);
          document.querySelector('meta[name="twitter:image"]').setAttribute("content", getSeoPath.TWITTER.image);
        } 
      }
    }
  },
  methods:{
    changeEntity(getEntity){
      this.getEntity = getEntity
      if(localStorage.getItem("route_path")){
        // console.log('11111111111111')
        let getEntityFromUrl = localStorage.getItem("route_path").split('/')[1]
        // console.log('getEntityFromUrl==============',getEntityFromUrl)
        // if(getEntity == 'AE'){
        //   window.location.replace('https://capitalwallet.ae/');
        // }else 
        
        if(getEntity == 'APAC'){
          this.$store.commit(Const.SET_ENTITY_NAME, 'apec')   
          this.$store.commit(Const.SET_ENTITY_PATH, '/au')
          if(getEntityFromUrl !== 'au'){
            this.$router.push('/au/')
          } 
        }else{ 
          this.$store.commit(Const.SET_ENTITY_NAME, 'global')  
          this.$store.commit(Const.SET_ENTITY_PATH, '/global') 
          if(getEntityFromUrl !== 'global'){
            this.$router.push('/global/')
          }
        }
      }else{
        if(getEntity == 'APAC'){
          this.$store.commit(Const.SET_ENTITY_NAME, 'apec')   
          this.$store.commit(Const.SET_ENTITY_PATH, '/au')
        }else{
          this.$store.commit(Const.SET_ENTITY_NAME, 'global')  
          this.$store.commit(Const.SET_ENTITY_PATH, '/global') 
        }
      }
      this.$store.commit(Const.SET_CONTENT_LIST, contentListFile[0])
    },
    call_faqs(){
      let sendData = {
          'globalParams':{},
          'localParams':{'parameter':this}
      }
      this._runGetFaqsList(sendData)
    }
  },
  created(){
  // console.log('my code11112')
    //entityName
    this.call_faqs()
    this.$store.commit(Const.SET_CONTENT_LIST, contentListFile[0])  
    this._runGetCountry({'globalParams' :{},'localParams' :{ "parameter" : this , "link" :'country'}})
    
  }
};
</script>
