/* eslint-disable */
import * as RxFactory from './api/rx-factory'
import * as Enpoints from './api/endpoints'
export function _executeApi(apiLink, credentials, getToken, commit, callback) {
    try {
        let response_array = [], headersJson = {} ;
        if (credentials.localParams.type == 'form') { credentials.globalParams = credentials.globalParams.form }
        /*if (getToken == '') {
            return
            // getToken = '' 
        }else if(getToken.Authorization && getToken.Authorization.includes('undefined')){
            return
        }*/

        if(getToken.Authorization && getToken.Authorization.includes('undefined')){
            headersJson['x-auth-token'] = 'b6de4414f12a3cbf8e9b7054a6532e80-ht6950a408bc46ee5f891f12f665189f12e9b50c920fab1ce0d7adb497439a493e0f06f61e29339bb31bb15ba37f540bbde31b317d1b15d4312629104d3fe189'
        }
        if (credentials.localParams.page && credentials.localParams.page > 1) {
            credentials.localParams.parameter.pageLoader1 = true
        } else {
            credentials.localParams.parameter.pageLoader = true
        }
        // console.log('getToken=============',getToken)
        // let link = (credentials.localParams.link)?apiLink:Enpoints.GLOBAL_BASE_URL + apiLink
        let link = apiLink
        if (credentials.localParams.link) {
            RxFactory.get(link, credentials.globalParams, headersJson)
                .then(({ data }) => {
                    // console.log('api-data',data,'url',apiLink)
                    // if(Object.keys(data).length == 0){
                    //     callback(false, {"info":"NOK","msg":{"code":"TECH_ERROR","msg":"Some Technical issue"},"data":{},"errors":error})
                    // }
                    
                    if (credentials.localParams.page && credentials.localParams.page > 1) {
                        credentials.localParams.parameter.pageLoader1 = false
                    } else {
                        credentials.localParams.parameter.pageLoader = false
                    }
                    // console.log('data===>',data)
                    if ('info' in data) {
                        if (data.info === 'OK') {
                            response_array.res_data = data.data
                            callback(false, response_array)
                        } else {
                            if (typeof data.msg == 'object') {
                                response_array.res_data = data.msg.msg
                            } else {
                                response_array.res_data = data.msg
                            }
                            callback(true, response_array)
                        }
                    }else{
                        if ('walletCurrencyList' in data) {
                            response_array.res_data = data.walletCurrencyList
                            callback(false, response_array)
                        }else if(credentials.localParams.link == "country") {
                            callback(false, data)
                        }else{
                            response_array.res_data = 'no data fetch from api'
                            callback(true, response_array)
                        }
                    }
                }).catch(error => {
                    _handleErrorResponse(error, commit)
                })
        } else {
            RxFactory.post(link, credentials.globalParams, headersJson)
                .then(({ data }) => {
                    // console.log('api-data',data,'url',apiLink)
                    // if(Object.keys(data).length == 0){
                    //     callback(false, {"info":"NOK","msg":{"code":"TECH_ERROR","msg":"Some Technical issue"},"data":{},"errors":error})
                    // }
                    if (credentials.localParams.page && credentials.localParams.page > 1) {
                        credentials.localParams.parameter.pageLoader1 = false
                    } else {
                        credentials.localParams.parameter.pageLoader = false
                    }
                    // console.log('00000000000000', data)
                    if ('info' in data) {
                        if (data.info === 'OK') {
                            response_array.res_data = data.data
                            callback(false, response_array)
                        } else {
                            if (typeof data.msg == 'object') {
                                response_array.res_data = data.msg.msg
                            } else {
                                response_array.res_data = data.msg
                            }
                            callback(true, response_array)
                        }
                    }
                }).catch((error) => {
                    _handleErrorResponse(error, commit)
                })
        }
    } catch (ex) {
        console.log('ss', ex)
        _handleErrorResponse(ex, commit)
    }
}

export function _messageDisplayFunction(getParameter, getMessage, msgType) {
    //getParameter.response_msg = getMessage
    //getParameter.msg_type = msgType
    getParameter.$notify({
        group: 'foo',
        type: msgType,
        text: getMessage,
        duration: 10000,
        closeOnClick: true
    });
}

export function _cleanUp(commit, msg,) {// status, loaderId
    // let loaderArr = (typeof loaderId !== 'undefined') ? [loaderId] : []
    // commit("SET_INFO", msg)
}

export function _handleErrorResponse(e, commit) {
    let response = { info: 'NOK', msg: 'Service not available' }
    if (typeof e.response === 'undefined') {
        try {
            const statusCheck = e.toString().toLowerCase()
            if (statusCheck.indexOf('network') > -1) {
                response = { info: 'NOK', msg: 'Network Error' }
            }
        } catch (e) { console.log(e) }
    } else {
        response = e.response.data
    }
    _cleanUp(commit, response, response.status)
    if (typeof e.response !== 'undefined') {
        if (e.response.status == 401) {
            let data = {}
            let options = {}
            options.forward = true
            _handleLogout(data, commit, options)
        }
    }
}

export function _handleLogout(data, commit, options) {
    // const router = options.router
    // let user = {}
    localStorage.clear();
    if ('msg' in data && 'info' in data) {
        _cleanUp(commit, data.msg, data.info)
    }
    if (options.forward === true) {
        window.location.replace("/")
    }
}

export function formatPriceCommon(price, isCrypto) {
    if (typeof price != 'number')
        price = parseFloat(price)
    if (isNaN(price))
        return 0
    price = price.toFixed(isCrypto ? 8 : 2)
    // return price.replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, '$1')
    let stringPrice = parseInt(price).toLocaleString() + ((price.toString().indexOf('.') != -1) ? ('.' + price.split('.')[1]) : '')
    if (isCrypto) {
        return stringPrice.replace(/(?:(\.\d*?[1-9]+)|\.)0*$/gm, '$1')
    } else {
        return stringPrice
    }
}
