<template>
  <Header />
  <div id="main-wrapper">
    <router-view />
  </div>
  <Footer />
</template>

<script>
import Header from "@/components/shared/header";
import Footer from "@/components/shared/footer";

export default {
  data() {
    return {
    };
  },
  components: { Header, Footer },
};
</script>

